import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import './ModalComponent.css';

function ModalComponent({ title, body, buttonLabel, styles = {}, template = "Default" }) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const renderContent = () => {
        switch (template) {
            case "Modern":
                return (
                    <>
                        <Modal.Header
                            closeButton
                            style={{
                                backgroundColor: styles?.header?.backgroundColor || '#007bff',
                                color: styles?.header?.color || '#fff',
                            }}
                        >
                            <Modal.Title>{title || 'Modern Modal Title'}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={styles?.body}>
                            {body || 'Modern Modal Body Content'}
                        </Modal.Body>
                        <Modal.Footer style={styles?.footer}>
                            <Button
                                variant="outline-light"
                                style={{
                                    backgroundColor: styles?.button?.backgroundColor || '#28a745',
                                    color: styles?.button?.color || '#fff',
                                }}
                                onClick={handleClose}
                            >
                                Close
                            </Button>
                        </Modal.Footer>
                    </>
                );
            case "Minimal":
                return (
                    <>
                        <Modal.Body style={styles?.body}>
                            <h5 style={styles?.header}>{title || 'Minimal Modal Title'}</h5>
                            <p>{body || 'Minimal Modal Body Content'}</p>
                        </Modal.Body>
                        <Modal.Footer style={styles?.footer}>
                            <Button
                                variant="outline-secondary"
                                onClick={handleClose}
                                style={{
                                    ...styles?.button,
                                }}
                            >
                                Okay
                            </Button>
                        </Modal.Footer>
                    </>
                );
            default: // Default Template
                return (
                    <>
                        <Modal.Header closeButton>
                            <Modal.Title>{title || 'Default Modal Title'}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>{body || 'Default Modal Body Content'}</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </>
                );
        }
    };

    return (
        <>
            <Button
                variant="primary"
                onClick={handleShow}
                style={{
                    backgroundColor: styles?.triggerButton?.backgroundColor || '#007bff',
                    color: styles?.triggerButton?.color || '#fff',
                }}
            >
                {buttonLabel || 'Open Modal'}
            </Button>

            <Modal show={show} onHide={handleClose}>
                {renderContent()}
            </Modal>
        </>
    );
}

export default ModalComponent;
