import React from 'react';

function VideoComponent({ src, styles = {} }) {
    // List of supported platforms and validation logic
    const isYouTubeUrl = src && src.includes('youtube.com/watch?v=');
    const isYouTubeShortUrl = src && src.includes('youtu.be/');
    const isVimeoUrl = src && src.includes('vimeo.com');

    const videoStyles = styles.videoStyle || {}; // Apply styles dynamically

    if (isYouTubeUrl || isYouTubeShortUrl) {
        // Convert to YouTube embed URL
        const embedUrl = src.includes('youtu.be/')
            ? src.replace('youtu.be/', 'youtube.com/embed/')
            : src.replace('watch?v=', 'embed/');
        return (
            <iframe
                src={embedUrl}
                title="YouTube Video"
                style={videoStyles}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            ></iframe>
        );
    }

    if (isVimeoUrl) {
        // Convert to Vimeo embed URL
        const embedUrl = src.replace('vimeo.com', 'player.vimeo.com/video');
        return (
            <iframe
                src={embedUrl}
                title="Vimeo Video"
                style={videoStyles}
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
            ></iframe>
        );
    }

    // If URL is unsupported
    return <p style={{ color: 'red' }}>Unsupported video URL. Please provide a valid YouTube or Vimeo link.</p>;
}

export default VideoComponent;
