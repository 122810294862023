import React from 'react';
import { Form, Row, Col, Button, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { Accordion } from 'react-bootstrap';

const styleCategories = {
  Typography: [
    'fontSize',
    'fontFamily',
    'fontWeight',
    'fontStyle',
    'lineHeight',
    'letterSpacing',
    'textAlign',
    'textDecoration',
  ],
  Spacing: ['margin', 'padding', 'gap'],
  Borders: ['border', 'borderRadius', 'borderColor', 'borderWidth'],
  Colors: ['color', 'backgroundColor'],
  Layout: ['width', 'height', 'boxShadow', 'display', 'alignItems', 'justifyContent', 'alignment', 'sizing'],
  Links: ['link'], // Added as a separate category for link styles
};


const styleInputTypes = {
  // Numeric fields with steppers
  fontSize: 'stepper',
  borderRadius: 'stepper',
  margin: 'side-stepper',
  padding: 'side-stepper',
  width: 'stepper',
  height: 'stepper',

  // Dropdown fields
  fontFamily: 'dropdown',
  fontWeight: 'dropdown',
  fontStyle: 'dropdown',
  lineHeight: 'stepper',
  letterSpacing: 'stepper',
  borderLeftWidth: 'stepper',
  textAlign: 'dropdown',
  textDecoration: 'dropdown',
  overflow: 'dropdown',
  cursor: 'dropdown',
  alignment: 'dropdown',

  // Color pickers
  color: 'color',
  backgroundColor: 'color',
  dotColor: 'color',
  arrowColor: 'color',
  borderColor: 'color',
  borderLeftColor: 'color',

  // Text fields
  boxShadow: 'text',
  link: 'text',
  border: 'border',
  opacity: 'text',
};

const options = {
  fontFamily: [
    { label: 'Arial', value: 'Arial, sans-serif' },
    { label: 'Georgia', value: 'Georgia, serif' },
    { label: 'Times New Roman', value: '"Times New Roman", Times, serif' },
    { label: 'Courier New', value: '"Courier New", Courier, monospace' },
    { label: 'Verdana', value: 'Verdana, sans-serif' },
    { label: 'Roboto', value: 'Roboto, sans-serif' },
    { label: 'Montserrat', value: 'Montserrat, sans-serif' },
    { label: 'Open Sans', value: 'Open Sans, sans-serif' },
    { label: 'Lato', value: 'Lato, sans-serif' },
    { label: 'Poppins', value: 'Poppins, sans-serif' },
    { label: 'Raleway', value: 'Raleway, sans-serif' },
    { label: 'Oswald', value: 'Oswald, sans-serif' },
    { label: 'Merriweather', value: 'Merriweather, serif' },
    { label: 'Playfair Display', value: 'Playfair Display, serif' },
    { label: 'Nunito', value: 'Nunito, sans-serif' },
  ],
  fontWeight: [
    'normal',
    'bold',
    'bolder',
    'lighter',
    '100',
    '200',
    '300',
    '400',
    '500',
    '600',
    '700',
    '800',
    '900',
  ],
  fontStyle: ['normal', 'italic', 'oblique'],
  lineHeight: ['normal', '1', '1.2', '1.5', '1.6', '1.8', '2', '2.5', '3'],
  letterSpacing: [
    'normal',
    '0px',
    '1px',
    '2px',
    '3px',
    '4px',
    '5px',
    '10px',
  ],
  textAlign: ['left', 'center', 'right', 'justify'],
  textDecoration: ['none', 'underline', 'line-through', 'overline', 'dotted'],
  overflow: ['visible', 'hidden', 'scroll', 'auto'],
  cursor: ['default', 'pointer', 'move', 'not-allowed', 'help', 'text'],
  alignment: ['left', 'center', 'right'],
};

const formatLabel = (field) =>
  field
    .replace(/([A-Z])/g, ' $1') // Add a space before uppercase letters
    .replace(/^./, (str) => str.toUpperCase()) // Capitalize the first letter
    .toLowerCase() // Convert everything to lowercase
    .replace(/^\w/, (str) => str.toUpperCase()); // Ensure first word starts uppercase

const StyleEditor = ({
  styles = {},
  defaultStyles = {},
  onChange,
  editableFields = [],
}) => {
  const handleStyleChange = (field, value) => {
    onChange({ ...styles, [field]: value });
  };

  const handleResetStyle = (field) => {
    handleStyleChange(field, defaultStyles[field] || '');
  };

  const handleIncrement = (field, increment) => {
    const currentValue = parseInt(styles[field] || '0', 10);
    handleStyleChange(field, `${currentValue + increment}px`);
  };

  const renderStepper = (field, showResetButton = true) => (
    <InputGroup className="stepper-style">
      <Button
        variant="outline-secondary"
        onClick={() => handleIncrement(field, -1)}
      >
        -
      </Button>
      <Form.Control
        type="text"
        value={styles[field] || ''}
        onChange={(e) => {
          const value = e.target.value;
          handleStyleChange(field, value); // Directly pass the user input
        }}
        className="text-center"
      />
      <Button
        variant="outline-secondary"
        onClick={() => handleIncrement(field, 1)}
      >
        +
      </Button>
    </InputGroup>
  );

  const renderBorderEditor = (field) => (
    <div>
      <div className="styling-label">
        <Form.Label>{formatLabel(field)}</Form.Label>
        {/* Single Reset All Button */}
        <Button
          variant="outline-secondary"
          size="sm"
          onClick={() => {
            const updatedStyles = { ...styles }; // Clone current styles
            ['Top', 'Right', 'Bottom', 'Left'].forEach((side) => {
              updatedStyles[`${field}${side}Width`] =
                defaultStyles[`${field}${side}Width`] || '0px'; // Reset width
              updatedStyles[`${field}${side}Style`] =
                defaultStyles[`${field}${side}Style`] || 'none'; // Reset style
              updatedStyles[`${field}${side}Color`] =
                defaultStyles[`${field}${side}Color`] || '#000000'; // Reset color
            });
            onChange(updatedStyles); // Update styles
          }}
        >
          <FontAwesomeIcon icon={faSyncAlt} /> Reset All
        </Button>
      </div>

      {['Top', 'Right', 'Bottom', 'Left'].map((side) => (
        <div key={side} className="mb-3">
          <h5>{`${side} Border`}</h5>
          <Row>
            <Col>
              <Form.Label>{`${side} Width`}</Form.Label>
              <InputGroup>
                <Button
                  variant="outline-secondary"
                  onClick={() =>
                    handleIncrement(`${field}${side}Width`, -1)
                  }
                >
                  -
                </Button>
                <Form.Control
                  type="text"
                  value={styles[`${field}${side}Width`] || '0px'}
                  onChange={(e) =>
                    handleStyleChange(`${field}${side}Width`, e.target.value)
                  }
                  className="text-center"
                />
                <Button
                  variant="outline-secondary"
                  onClick={() =>
                    handleIncrement(`${field}${side}Width`, 1)
                  }
                >
                  +
                </Button>
              </InputGroup>
            </Col>
            <Col>
              <Form.Label>{`${side} Style`}</Form.Label>
              <Form.Control
                as="select"
                value={styles[`${field}${side}Style`] || 'none'}
                onChange={(e) =>
                  handleStyleChange(`${field}${side}Style`, e.target.value)
                }
              >
                <option value="none">None</option>
                <option value="solid">Solid</option>
                <option value="dashed">Dashed</option>
                <option value="dotted">Dotted</option>
                <option value="double">Double</option>
              </Form.Control>
            </Col>
            <Col>
              <Form.Label>{`${side} Color`}</Form.Label>
              <Form.Control
                type="color"
                value={styles[`${field}${side}Color`] || '#000000'}
                onChange={(e) =>
                  handleStyleChange(`${field}${side}Color`, e.target.value)
                }
              />
            </Col>
          </Row>
        </div>
      ))}
    </div>
  );

  const renderSideSteppers = (field) => (
    <div>
      <div className="styling-label">
        <Form.Label>{formatLabel(field)}</Form.Label>
        {/* Single Reset All Button */}
        <Button
          variant="link"
          size="sm"
          onClick={() => {
            const updatedStyles = { ...styles }; // Clone current styles
            ['Top', 'Right', 'Bottom', 'Left'].forEach((side) => {
              updatedStyles[`${field}${side}`] =
                defaultStyles[`${field}${side}`] || '0px'; // Reset to default or '0px'
            });
            onChange(updatedStyles); // Update all styles at once
          }}
        >
          <FontAwesomeIcon icon={faSyncAlt} /> Reset
        </Button>
      </div>
      <Row className="l-in-label">
        {['Top', 'Right', 'Bottom', 'Left'].map((side) => (
          <Col key={side}>
            {/* Pass false to hide the Reset button for individual steppers */}
            {renderStepper(`${field}${side}`, false)}
            <Form.Label className="text-center">{side}</Form.Label>
          </Col>
        ))}
      </Row>
    </div>
  );

  const renderField = (field) => {
    if (styleInputTypes[field] === 'border') {
      return renderBorderEditor(field);
    } else if (styleInputTypes[field] === 'side-stepper') {
      return renderSideSteppers(field);
    } else {
      return (
        <>
          <div className="styling-label">
            <Form.Label>{formatLabel(field)}</Form.Label>
            <Button
              variant="outline-secondary"
              size="sm"
              onClick={() => handleResetStyle(field)}
            >
              <FontAwesomeIcon icon={faSyncAlt} /> Reset
            </Button>
          </div>
          {styleInputTypes[field] === 'stepper' ? (
            renderStepper(field)
          ) : styleInputTypes[field] === 'dropdown' ? (
            <InputGroup>
              <Form.Control
                as="select"
                name={field}
                value={styles[field] || ''}
                onChange={(e) => handleStyleChange(field, e.target.value)}
              >
                <option value="">Select</option>
                {options[field].map((option) => (
                  <option
                    key={option.value || option}
                    value={option.value || option}
                  >
                    {option.label || option}
                  </option>
                ))}
              </Form.Control>
            </InputGroup>
          ) : styleInputTypes[field] === 'color' ? (
            <InputGroup>
              <Form.Control
                type="color"
                name={field}
                value={styles[field] || '#000000'}
                onChange={(e) => handleStyleChange(field, e.target.value)}
              />
            </InputGroup>
          ) : (
            <InputGroup>
              <Form.Control
                type="text"
                name={field}
                value={styles[field] || ''}
                onChange={(e) => handleStyleChange(field, e.target.value)}
                placeholder={
                  field === 'boxShadow'
                    ? 'e.g., 0px 4px 10px rgba(0,0,0,0.25)'
                    : ''
                }
              />
            </InputGroup>
          )}
        </>
      );
    }
  };

  return (
    <Accordion defaultActiveKey="0">
      {Object.keys(styleCategories).map((category, idx) => {
        // Filter fields based on editableFields
        const filteredFields = styleCategories[category].filter((field) =>
          editableFields.length === 0 || editableFields.includes(field)
        );

        // Skip categories with no editable fields
        if (filteredFields.length === 0) return null;

        return (
          <Accordion.Item key={category} eventKey={idx}>
            <Accordion.Header>{category}</Accordion.Header>
            <Accordion.Body>
              {filteredFields.map((field) => (
                <Form.Group key={field} className="mb-3 styling-form-inputs">
                  {renderField(field)}
                </Form.Group>
              ))}
            </Accordion.Body>
          </Accordion.Item>
        );
      })}
    </Accordion>
  );
};

export default StyleEditor;
