import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faCheckCircle, faCircle, faStar, faArrowRight } from '@fortawesome/free-solid-svg-icons';

import 'bootstrap/dist/css/bootstrap.min.css';
import './ListGroupCardTemplates.css'; // CSS for templates

const ListGroupCard = ({ title, items, styles = {}, template = "Default" }) => {
    // Map string keys to FontAwesome icon definitions
    const iconMap = {
        faAngleRight: faAngleRight,
        faCheckCircle: faCheckCircle,
        faCircle: faCircle,
        faStar: faStar,
        faArrowRight: faArrowRight,
    };

    const renderItems = (defaultIcon) =>
        items.map((item, index) => {
            const itemIcon = typeof item.icon === 'string' ? iconMap[item.icon] : defaultIcon; // Map string to icon
            const itemText = typeof item === 'string' ? item : item.text; // Support plain strings for backward compatibility

            return (
                <li
                    key={index}
                    className="list-group-item align-items-center"
                    style={styles.item}
                >
                    {itemIcon && <FontAwesomeIcon icon={itemIcon} style={styles.icon} />}
                    {itemText && <span style={{  }}>{itemText}</span>}
                </li>
            );
        });

    switch (template) {
        case "Outlined":
            return (
                <div className="card outlined-list-group">
                    <div className="card-header" style={styles.header}>{title}</div>
                    <ul className="list-group list-group-flush">{renderItems(iconMap.faCheckCircle)}</ul>
                </div>
            );

        case "Gradient":
            return (
                <div className="card gradient-list-group">
                    <div className="card-header text-white" style={styles.header}>{title}</div>
                    <ul className="list-group list-group-flush">{renderItems(iconMap.faStar)}</ul>
                </div>
            );

        case "Minimal":
            return (
                <div className="card minimal-list-group">
                    <div className="card-header" style={styles.header}>{title}</div>
                    <ol className="list-group">{renderItems(iconMap.faCircle)}</ol>
                </div>
            );

        case "IconOnly":
            return (
                <div className="card icon-only-list-group">
                    <ul className="list-group">{renderItems(iconMap.faArrowRight)}</ul>
                </div>
            );

        default:
            return (
                <div className="card default-list-group">
                    <div className="card-header" style={styles.header}>{title}</div>
                    <ul className="list-group list-group-flush">{renderItems(iconMap.faAngleRight)}</ul>
                </div>
            );
    }
};

export default ListGroupCard;
