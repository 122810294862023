import React from 'react';
import './HeadingTemplates.css'; // Import CSS file

function HeadingComponent({ level, text, styles, template = "Dotted-Underline" }) {
    const HeadingTag = `h${level}`;
    const containerClassName = `${template.toLowerCase()}`; // Class for parent div
    const appliedStyles = styles?.text || {}; // Inline styles for the heading

    return (
        <div className={containerClassName}>
            <HeadingTag style={appliedStyles}>
                {text || `Heading ${level}`}
            </HeadingTag>
        </div>
    );
}

export default HeadingComponent;
