import React from 'react';
import { Form, Button, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';

const BreadcrumbForm = ({ formValues, setFormValues, handleAddJsonArrayItem, handleRemoveJsonArrayItem }) => {
    const renderJsonArrayFields = (fieldName, itemSchema) => (
        <>
            {formValues[fieldName]?.map((item, index) => (
                <div key={index} className="breadcrumb-item-form mb-4">
                    <Form.Group>
                        <Form.Label>Text</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter breadcrumb text"
                            value={item.text || ''}
                            onChange={(e) => {
                                const updatedItems = [...formValues[fieldName]];
                                updatedItems[index].text = e.target.value;
                                setFormValues({ ...formValues, [fieldName]: updatedItems });
                            }}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Link</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter breadcrumb link"
                            value={item.link || ''}
                            onChange={(e) => {
                                const updatedItems = [...formValues[fieldName]];
                                updatedItems[index].link = e.target.value;
                                setFormValues({ ...formValues, [fieldName]: updatedItems });
                            }}
                        />
                    </Form.Group>
                    <Button
                        variant="danger"
                        className="mt-2"
                        onClick={() => handleRemoveJsonArrayItem(fieldName, index)}
                    >
                        <FontAwesomeIcon icon={faTrash} /> Remove Item
                    </Button>
                </div>
            ))}
            <Button
                variant="primary"
                className="mt-2"
                onClick={() => handleAddJsonArrayItem(fieldName, itemSchema)}
            >
                <FontAwesomeIcon icon={faPlus} /> Add Breadcrumb Item
            </Button>
        </>
    );

    return (
        <div className="breadcrumb-form">
            <Form.Group>
                <Form.Label>Separator</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter separator (e.g., /, >, |)"
                    value={formValues.separator || ''}
                    onChange={(e) =>
                        setFormValues({ ...formValues, separator: e.target.value })
                    }
                />
            </Form.Group>
            {renderJsonArrayFields('items', { text: '', link: '' })}
        </div>
    );
};

export default BreadcrumbForm;
