// src/utils/safeParseJSON.js

export function safeParseJSON(field, defaultValue = {}) {
    if (typeof field === 'string') {
        try {
            const parsed = JSON.parse(field);
            // Ensure the parsed result is an object and not an array
            if (parsed && typeof parsed === 'object' && !Array.isArray(parsed)) {
                return parsed;
            }
        } catch (e) {
            console.error('Failed to parse JSON:', e);
        }
    } else if (typeof field === 'object' && !Array.isArray(field)) {
        return field;
    }
    return defaultValue;
}
