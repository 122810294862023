export const templateConfig = {
    carousel: [
        {
            templateName: 'Classic',
            previewImage: 'https://www.fiklu.com/media/79'
        },
        {
            templateName: 'Modern',
            previewImage: 'https://www.fiklu.com/media/79'
        },
        {
            templateName: 'Minimal',
            previewImage: 'https://www.fiklu.com/media/79'
        }
    ],
    cardComponent: [
        {
            templateName: 'Standard Card',
            previewImage: 'https://www.fiklu.com/media/79'
        },
        {
            templateName: 'Fancy Card',
            previewImage: 'https://www.fiklu.com/media/79'
        }
    ],
    heading: [
        {
            templateName: 'Classic-Heading',
            previewImage: 'https://www.fiklu.com/media/79',
            styles: {
                text: {
                    fontFamily: 'Georgia, serif',
                    fontSize: '32px',
                    fontWeight: 'bold',
                    color: '#333',
                    textAlign: 'center',
                },
            },
        },
        {
            templateName: 'Dotted-Underline-Heading',
            previewImage: 'https://www.fiklu.com/media/79',
            styles: {
                text: {
                    fontFamily: 'Arial, sans-serif',
                    fontSize: '28px',
                    fontWeight: '500',
                    color: '#444',
                    textDecoration: 'underline dotted',
                    textAlign: 'left',
                },
            },
        },
        {
            templateName: 'Minimal-Heading',
            previewImage: 'https://www.fiklu.com/media/79',
            styles: {
                text: {
                    fontFamily: 'Roboto, sans-serif',
                    fontSize: '24px',
                    fontWeight: '400',
                    color: '#777',
                    textAlign: 'left',
                    margin: '10px 0',
                },
            },
        },
        {
            templateName: 'Gradient-Heading',
            previewImage: 'https://www.fiklu.com/media/79',
            styles: {
                text: {
                    fontFamily: 'Helvetica, sans-serif',
                    fontSize: '36px',
                    fontWeight: '600',
                    background: 'linear-gradient(to right, #ff7e5f, #feb47b)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    textAlign: 'center',
                },
            },
        },
        {
            templateName: 'Shadow-Heading',
            previewImage: 'https://www.fiklu.com/media/79',
            styles: {
                text: {
                    fontFamily: 'Verdana, sans-serif',
                    fontSize: '30px',
                    fontWeight: 'bold',
                    color: '#222',
                    textShadow: '2px 2px 5px rgba(0, 0, 0, 0.3)',
                    textAlign: 'center',
                },
            },
        },
        {
            templateName: 'Boxed-Heading',
            previewImage: 'https://www.fiklu.com/media/79',
            styles: {
                text: {
                    fontFamily: 'Times New Roman, serif',
                    fontSize: '28px',
                    fontWeight: '500',
                    color: '#000',
                    backgroundColor: '#f2f2f2',
                    padding: '10px',
                    borderRadius: '8px',
                    textAlign: 'center',
                },
            },
        },
    ],

    image: [
        {
            templateName: 'Default',
            previewImage: 'https://www.fiklu.com/media/default-image',
        },
        {
            templateName: 'Rounded',
            previewImage: 'https://www.fiklu.com/media/rounded-image',
        },
        {
            templateName: 'Shadow',
            previewImage: 'https://www.fiklu.com/media/shadow-image',
        },
        {
            templateName: 'Framed',
            previewImage: 'https://www.fiklu.com/media/framed-image',
        },
        {
            templateName: 'Polaroid',
            previewImage: 'https://www.fiklu.com/media/polaroid-image',
        },
        {
            templateName: 'Circle-Frame',
            previewImage: 'https://www.fiklu.com/media/circle-frame-image',
        },
    ],
    button: [
        {
            templateName: 'Default',
            previewImage: 'https://www.fiklu.com/media/default-button',
        },
        {
            templateName: 'Outline',
            previewImage: 'https://www.fiklu.com/media/outline-button',
        },
        {
            templateName: 'Rounded',
            previewImage: 'https://www.fiklu.com/media/rounded-button',
       },
        {
            templateName: 'Gradient',
            previewImage: 'https://www.fiklu.com/media/gradient-button',
        },
        {
            templateName: 'Shadow',
            previewImage: 'https://www.fiklu.com/media/shadow-button',
        },
    ],
    tabs: [
        {
            templateName: 'Default',
            previewImage: 'https://www.fiklu.com/media/default-tabs',
        },
        {
            templateName: 'Classic',
            previewImage: 'https://www.fiklu.com/media/classic-tabs',
        },
        {
            templateName: 'Modern',
            previewImage: 'https://www.fiklu.com/media/modern-tabs',
        },
        {
            templateName: 'Minimal',
            previewImage: 'https://www.fiklu.com/media/minimal-tabs',
        },
        {
            templateName: 'Boxed',
            previewImage: 'https://www.fiklu.com/media/boxed-tabs',
        },
    ],
    accordion: [
        {
            templateName: 'Default',
            previewImage: 'https://www.fiklu.com/media/default-accordion',
        },
        {
            templateName: 'Minimal',
            previewImage: 'https://www.fiklu.com/media/minimal-accordion',
        },
        {
            templateName: 'Outlined',
            previewImage: 'https://www.fiklu.com/media/outlined-accordion',
        },
    ],

    horizontalCard: [
        {
            templateName: 'Default',
            previewImage: 'https://www.fiklu.com/media/default-horizontal-card',
        },
        {
            templateName: 'HorizontalCardLeft',
            previewImage: 'https://www.fiklu.com/media/split-border-horizontal-card',
        },
        {
            templateName: 'HorizontalCardTop',
            previewImage: 'https://www.fiklu.com/media/modern-outline-horizontal-card',
        },
        {
            templateName: 'HorizontalCardBottom',
            previewImage: 'https://www.fiklu.com/media/modern-outline-horizontal-card',
        },
        {
            templateName: 'HorizontalCardRoundedLeft',
            previewImage: 'https://www.fiklu.com/media/framed-bg-horizontal-card',
        },
        {
            templateName: 'HorizontalCardRoundedTop',
            previewImage: 'https://www.fiklu.com/media/split-border-horizontal-card',
        },
    ],
    imageOverlayCard: [
        {
            templateName: 'Default',
            previewImage: 'https://www.fiklu.com/media/default-image-overlay-card',
        },
        {
            templateName: 'CenterAlignedOverlay',
            previewImage: 'https://www.fiklu.com/media/center-aligned-overlay-card',
        },
        {
            templateName: 'BottomOverlay',
            previewImage: 'https://www.fiklu.com/media/bottom-overlay-card',
        },
        {
            templateName: 'GradientOverlay',
            previewImage: 'https://www.fiklu.com/media/gradient-overlay-card',
        },
        {
            templateName: 'TransparentOverlay',
            previewImage: 'https://www.fiklu.com/media/transparent-overlay-card',
        },
    ],
    listGroupCard: [
        {
            templateName: 'Default',
            previewImage: 'https://www.fiklu.com/media/default-list-group-card',
        },
        {
            templateName: 'Outlined',
            previewImage: 'https://www.fiklu.com/media/outlined-list-group-card',
        },
        {
            templateName: 'Gradient',
            previewImage: 'https://www.fiklu.com/media/gradient-list-group-card',
        },
        {
            templateName: 'Minimal',
            previewImage: 'https://www.fiklu.com/media/minimal-list-group-card',
        },
        {
            templateName: 'IconOnly',
            previewImage: 'https://www.fiklu.com/media/icon-only-list-group-card',
        },
    ],
    pricingTable: [
        {
            templateName: 'Default',
            previewImage: 'https://www.fiklu.com/media/default-pricing-table',
        },
        {
            templateName: 'Classic',
            previewImage: 'https://www.fiklu.com/media/classic-pricing-table',
        },
        {
            templateName: 'Modern',
            previewImage: 'https://www.fiklu.com/media/modern-pricing-table',
        },
    ],
    faq: [
        {
            templateName: 'ModernClassic',
            previewImage: 'https://www.fiklu.com/media/faq-modern-classic',
        },
        {
            templateName: 'CollapsibleGradient',
            previewImage: 'https://www.fiklu.com/media/faq-collapsible-gradient',
        },
        {
            templateName: 'BoxedShadow',
            previewImage: 'https://www.fiklu.com/media/faq-boxed-shadow',
        },
        {
            templateName: 'Minimal',
            previewImage: 'https://www.fiklu.com/media/faq-minimal',
        },
        {
            templateName: 'Interactive',
            previewImage: 'https://www.fiklu.com/media/faq-interactive',
        },
    ],
    testimonialComponent: [
        {
            templateName: 'ImageOnTop',
            previewImage: 'https://www.example.com/media/image-on-top-testimonial',
        },
        {
            templateName: 'SplitScreen',
            previewImage: 'https://www.example.com/media/split-screen-testimonial',
        },
        {
            templateName: 'TextOnly',
            previewImage: 'https://www.example.com/media/text-only-testimonial',
        },
        {
            templateName: 'CenterAligned',
            previewImage: 'https://www.example.com/media/center-aligned-testimonial',
        },
    ],
    teamSection: [
        {
            templateName: 'Classic',
            previewImage: 'https://www.example.com/media/classic-team-template',
        },
        {
            templateName: 'Modern',
            previewImage: 'https://www.example.com/media/modern-team-template',
        },
        {
            templateName: 'Split',
            previewImage: 'https://www.example.com/media/split-team-template',
        },
        {
            templateName: 'CardOverlay',
            previewImage: 'https://www.example.com/media/card-overlay-team-template',
        },
        {
            templateName: 'MinimalGrid',
            previewImage: 'https://www.example.com/media/minimal-grid-team-template',
        },
    ],
    modal: [
        {
            templateName: 'Default',
            previewImage: 'https://www.example.com/media/default-modal',
        },
        {
            templateName: 'Modern',
            previewImage: 'https://www.example.com/media/modern-modal',
        },
        {
            templateName: 'Minimal',
            previewImage: 'https://www.example.com/media/minimal-modal',
        },
    ],

    blockquote: [
        {
            templateName: 'Default',
            previewImage: 'https://www.example.com/media/minimal-grid-team-template',
        },
        {
            templateName: 'Modern',
            previewImage: 'https://www.example.com/media/minimal-grid-team-template',
        },
        {
            templateName: 'Minimal',
            previewImage: 'https://www.example.com/media/minimal-grid-team-template',
        },
        {
            templateName: 'Roundedquote',
            previewImage: 'https://www.example.com/media/minimal-grid-team-template',
        }
    ],
    gallery: [
        {
            templateName: 'Grid',
            previewImage: 'https://www.example.com/media/gallery-grid',
        },
        {
            templateName: 'Carousel',
            previewImage: 'https://www.example.com/media/gallery-carousel',
        },
        {
            templateName: 'Overlay',
            previewImage: 'https://www.example.com/media/gallery-overlay',
        },
        {
            templateName: 'Lightbox',
            previewImage: 'https://www.example.com/media/gallery-polaroid',
        },
    ],
    iconCard: [
        {
            templateName: 'Modern',
            previewImage: 'https://www.example.com/media/gallery-grid',
        },
        {
            templateName: 'Minimal',
            previewImage: 'https://www.example.com/media/gallery-masonry',
        },
        {
            templateName: 'Classic',
            previewImage: 'https://www.example.com/media/gallery-polaroid',
        },
    ],
    headerFooterCard: [
        {
            templateName: 'Classic',
            previewImage: 'https://www.example.com/media/classic-hf-card',
        },
        {
            templateName: 'Modern',
            previewImage: 'https://www.example.com/media/modern-hf-card',
        },
        {
            templateName: 'Minimal',
            previewImage: 'https://www.example.com/media/minimal-hf-card',
        },
    ],
    progress: [
        {
            templateName: 'Classic',
            previewImage: 'https://www.example.com/media/classic-progress',
        },
        {
            templateName: 'Modern',
            previewImage: 'https://www.example.com/media/modern-progress',
        },
    ],
    breadcrumb: [
        {
            templateName: 'Default',
            previewImage: 'https://www.example.com/media/breadcrumb-default',
            description: 'Simple breadcrumb with default styling and separators.',
        },
        {
            templateName: 'Modern',
            previewImage: 'https://www.example.com/media/breadcrumb-modern',
            description: 'Modern breadcrumb with rounded backgrounds and clean separators.',
        },
        {
            templateName: 'Custom',
            previewImage: 'https://www.example.com/media/breadcrumb-pill',
            description: 'Breadcrumb styled with pill-shaped links for a sleek appearance.',
        },
    ],
    socialMediaIcons: [
        {
            templateName: 'Default',
            previewImage: 'https://www.example.com/media/gallery-grid',
        },
        {
            templateName: 'Modern',
            previewImage: 'https://www.example.com/media/gallery-carousel',
        },
        {
            templateName: 'Minimal',
            previewImage: 'https://www.example.com/media/gallery-overlay',
        },
        {
            templateName: 'Custom',
            previewImage: 'https://www.example.com/media/gallery-polaroid',
        },
    ],
    contactForm: [
        {
            templateName: 'SingleColumn',
            previewImage: 'https://www.example.com/media/gallery-grid',
        },
        {
            templateName: 'TwoColumn',
            previewImage: 'https://www.example.com/media/gallery-carousel',
        },
    ],

};
