import React, { useState } from 'react';
import { Form, Button, Image } from 'react-bootstrap';
import MediaLibrary from '../MediaLibrary';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

const GalleryForm = ({ formValues, setFormValues, handleAddJsonArrayItem, handleRemoveJsonArrayItem }) => {
    const renderJsonArrayFields = (fieldName, itemSchema) => (
        <>
            {formValues[fieldName]?.map((item, index) => (
                <div key={index} className="carousel-wrap">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="media-img-wrap">
                                <Form.Group>
                                    {item.src ? (
                                        <Image 
                                            src={item.src} 
                                            alt={item.alt || "Selected image"} 
                                            thumbnail 
                                            style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                                        />
                                    ) : (
                                        <div className="gallery-no-img">
                                            No image selected
                                        </div>
                                    )}

                                    {/* MediaLibrary Integration */}
                                    <MediaLibrary
                                        onSelectImage={(url) => {
                                            const updatedItems = [...formValues[fieldName]];
                                            updatedItems[index].src = url; // Update the src with the selected image URL
                                            setFormValues({ ...formValues, [fieldName]: updatedItems });
                                        }}
                                    />
                                </Form.Group>
                            </div>
                        </div>
                        <div className="col-md-9">
                            {Object.keys(itemSchema).map((key) => key !== 'src' && (
                                <Form.Control
                                    key={key}
                                    type="text"
                                    placeholder={key.charAt(0).toUpperCase() + key.slice(1)}
                                    value={item[key] || ''}
                                    onChange={(e) => {
                                        const updatedItems = [...formValues[fieldName]];
                                        updatedItems[index][key] = e.target.value;
                                        setFormValues({ ...formValues, [fieldName]: updatedItems });
                                    }}
                                    className="mb-2"
                                />
                            ))}
                        </div>
                    </div>
                    <Button variant="danger" className="mt-2" onClick={() => handleRemoveJsonArrayItem(fieldName, index)}>
                        Remove Image
                    </Button>
                </div>
            ))}
            <Button variant="primary" className="add-more-media" onClick={() => handleAddJsonArrayItem(fieldName, itemSchema)}>
                <FontAwesomeIcon icon={faPlus} /> Add Image
            </Button>
        </>
    );

    return (
        <>
            {renderJsonArrayFields('images', { src: '', alt: '', caption: '' })}
        </>
    );
};

export default GalleryForm;
