// CarouselComponent.jsx

import React from 'react';
import ClassicCarousel from './templates/carousel/ClassicCarousel';
import ModernCarousel from './templates/carousel/ModernCarousel';
import MinimalCarousel from './templates/carousel/MinimalCarousel';

function CarouselComponent({ items, template, styles = {} }) {
    switch (template) {
        case 'Modern':
            return <ModernCarousel items={items} styles={styles} />;
        case 'Minimal':
            return <MinimalCarousel items={items} styles={styles} />;
        case 'Classic':
        default:
            return <ClassicCarousel items={items} styles={styles} />;
    }
}

export default CarouselComponent;
