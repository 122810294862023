// src/componentForms/ContactFormForm.js

import React from 'react';
import { Form, Button, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';

const ContactFormForm = ({ formValues, setFormValues }) => {
    const handleFieldChange = (index, key, value) => {
        const updatedFields = [...(formValues.formFields || [])];
        updatedFields[index][key] = value;
        setFormValues({ ...formValues, formFields: updatedFields });
    };

    const handleAddField = () => {
        const newField = { type: 'text', label: 'New Field', name: `field_${Date.now()}`, placeholder: '', required: false };
        setFormValues({ ...formValues, formFields: [...(formValues.formFields || []), newField] });
    };

    const handleRemoveField = (index) => {
        const updatedFields = (formValues.formFields || []).filter((_, i) => i !== index);
        setFormValues({ ...formValues, formFields: updatedFields });
    };

    return (
        <Form>
            {(formValues.formFields || []).map((field, index) => (
                <div key={index} className="mb-4 border p-3">
                    <Form.Group className="mb-2">
                        <Form.Label>Field Type</Form.Label>
                        <Form.Select
                            value={field.type}
                            onChange={(e) => handleFieldChange(index, 'type', e.target.value)}
                        >
                            <option value="text">Text</option>
                            <option value="email">Email</option>
                            <option value="textarea">Textarea</option>
                            <option value="number">Number</option>
                            <option value="password">Password</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-2">
                        <Form.Label>Label</Form.Label>
                        <Form.Control
                            type="text"
                            value={field.label}
                            onChange={(e) => handleFieldChange(index, 'label', e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="mb-2">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            type="text"
                            value={field.name}
                            onChange={(e) => handleFieldChange(index, 'name', e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="mb-2">
                        <Form.Label>Placeholder</Form.Label>
                        <Form.Control
                            type="text"
                            value={field.placeholder}
                            onChange={(e) => handleFieldChange(index, 'placeholder', e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="mb-2">
                        <Form.Check
                            type="checkbox"
                            label="Required"
                            checked={field.required}
                            onChange={(e) => handleFieldChange(index, 'required', e.target.checked)}
                        />
                    </Form.Group>
                    <Button variant="danger" onClick={() => handleRemoveField(index)}>
                        <FontAwesomeIcon icon={faTrash} /> Remove Field
                    </Button>
                </div>
            ))}
            <Button variant="success" onClick={handleAddField}>
                <FontAwesomeIcon icon={faPlus} /> Add Field
            </Button>
        </Form>
    );
};

export default ContactFormForm;
