import React from 'react';
import { Form } from 'react-bootstrap';

const AlertForm = ({ formValues, setFormValues }) => (
    <>
        <div className="carousel-wrap">
            <Form.Group controlId="formAlertType" className="mb-3">
                <Form.Label>Alert Type</Form.Label>
                <Form.Control
                    as="select"
                    name="type"
                    value={formValues.type || ''}
                    onChange={(e) => setFormValues({ ...formValues, type: e.target.value })}
                >
                    <option value="warning">Warning</option>
                    <option value="success">Success</option>
                    <option value="danger">Danger</option>
                    <option value="info">Info</option>
                </Form.Control>
            </Form.Group>
            <Form.Group controlId="formAlertMessage">
                <Form.Label>Message</Form.Label>
                <Form.Control
                    type="text"
                    name="message"
                    value={formValues.message || ''}
                    onChange={(e) => setFormValues({ ...formValues, message: e.target.value })}
                />
            </Form.Group>
        </div>
    </>
);

export default AlertForm;
