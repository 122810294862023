import React from 'react';
import './ModernProgress.css';

const ModernProgress = ({ items, styles = {} }) => {
    const titleStyles = styles.title || {};
    const barStyles = styles.bar || {};
    const fillStyles = styles.fill || {};
    const percentageStyles = styles.percentage || {};

    return (
        <div className="progress-container modern-progress">
            {items.map((item, index) => (
                <div key={index} className="progress-item">
                    <div className="progress-header">
                        <span className="progress-title" style={titleStyles}>
                            {item.title}
                        </span>
                        <span className="progress-percentage" style={percentageStyles}>
                            {item.percentage}%
                        </span>
                    </div>
                    <div className="progress-bar" style={barStyles}>
                        <div
                            className="progress-fill"
                            style={{
                                ...fillStyles,
                                width: `${item.percentage}%`,
                            }}
                        ></div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ModernProgress;
