import React from 'react';
import { Form } from 'react-bootstrap';

const HeadingForm = ({ formValues, setFormValues }) => (
    <div className="carousel-wrap">
        {/* Heading Content */}
        <Form.Group controlId="formHeadingContent" className="mb-3">
            <Form.Label>Heading Content</Form.Label>
            <Form.Control
                type="text"
                placeholder="Enter heading text"
                value={formValues.text || ''}
                onChange={(e) => setFormValues({ ...formValues, text: e.target.value })}
            />
        </Form.Group>

        {/* Heading Level */}
        <Form.Group controlId="formHeadingLevel" className="mb-3">
            <Form.Label>Heading Level</Form.Label>
            <Form.Select
                value={formValues.level || '1'} // Default to "1" if formValues.level is not set
                onChange={(e) => setFormValues({ ...formValues, level: e.target.value })}
            >
                <option value="1">h1</option>
                <option value="2">h2</option>
                <option value="3">h3</option>
                <option value="4">h4</option>
                <option value="5">h5</option>
                <option value="6">h6</option>
            </Form.Select>
        </Form.Group>
    </div>
);

export default HeadingForm;
