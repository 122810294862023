import React from 'react';
import { Card } from 'react-bootstrap';
import './CardOverlayTeamTemplate.css'; // CSS file for templates

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';


const CardOverlayTeamTemplate = ({ headline, description, teamMembers, styles = {} }) => (
    <div className="card-overlay-wrap">
        <div className="row g-4 justify-content-center">
            {teamMembers.map((member, idx) => (
                <div key={idx} className="col-md-4">
                    <Card 
                        className="team-member-card"
                        
                        style={{
                            backgroundImage: `url(${member.image})`,
                            ...styles.image,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                        }}
                    >
                        <Card.ImgOverlay>
                            <Card.Title style={styles.name}>{member.name}</Card.Title>
                            <Card.Text style={styles.position}>{member.position}</Card.Text>
                            <div className="social-media-icons">
                                <a href={member.facebook} className="icon-link" style={styles.icon}>
                                    <FontAwesomeIcon icon={faFacebook} />
                                </a>
                                <a href={member.twitter} className="icon-link" style={styles.icon}>
                                    <FontAwesomeIcon icon={faTwitter} />
                                </a>
                                <a href={member.instagram} className="icon-link" style={styles.icon}>
                                    <FontAwesomeIcon icon={faInstagram} />
                                </a>
                                <a href={member.linkedin} className="icon-link" style={styles.icon}>
                                    <FontAwesomeIcon icon={faLinkedin} />
                                </a>
                            </div>
                        </Card.ImgOverlay>
                    </Card>
                </div>
            ))}
        </div>
    </div>
);

export default CardOverlayTeamTemplate;
