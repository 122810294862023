import React, { useState } from 'react';
import { Form, Button, Image } from 'react-bootstrap';
import MediaLibrary from '../MediaLibrary';

const ImageForm = ({ formValues, setFormValues }) => {
    const [showLibrary, setShowLibrary] = useState(false);

    const handleImageSelect = (url) => {
        setFormValues({ ...formValues, imgSrc: url });
        setShowLibrary(false); // Close media library after selection
    };

    return (
        <div className="carousel-wrap">
            <div className="row">
                <div className="col-md-3">
                    <div className="media-img-wrap">
                        {formValues.imgSrc ? (
                            <Image
                                src={formValues.imgSrc}
                                alt="Selected Image"
                                thumbnail
                                style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                            />
                        ) : (
                            <div
                                style={{
                                    width: '100px',
                                    height: '100px',
                                    backgroundColor: '#f0f0f0',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    border: '1px solid #ddd',
                                    borderRadius: '5px',
                                }}
                            >
                                No Image Selected
                            </div>
                        )}
                    </div>        
                </div>
                <div className="col-md-9 single-img-wrap-e">
                    <MediaLibrary
                        onSelectImage={handleImageSelect}
                        show={showLibrary}
                        setShow={setShowLibrary}
                    />
                    <Button variant="danger" className="mt-2">
                        Remove Image
                    </Button>
                </div>
            </div>

            
            
        </div>
    );
};

export default ImageForm;
