import React from 'react';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';

import './ModernTeamTemplate.css'; // CSS file for templates
const ModernTeamTemplate = ({ headline, description, teamMembers, styles = {} }) => (
    <div className="team-section-modern">
        {teamMembers.map((member, idx) => (
                <div className="team-member-modern text-center">
                    <div
                        className="image-circle mx-auto mb-3"
                        style={{
                            backgroundImage: `url(${member.image})`,
                            ...styles.image,
                            height: '140px',
                            width: '140px',
                            borderRadius: '50%',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                        }}
                    ></div>
                    <h5 style={styles.name}>{member.name}</h5>
                    <p style={styles.position}>{member.position}</p>
                    <div className="social-media-icons d-flex justify-content-center">
                        <a href={member.facebook} className="icon-link" style={styles.icon}>
                            <FontAwesomeIcon icon={faFacebook} />
                        </a>
                        <a href={member.twitter} className="icon-link" style={styles.icon}>
                            <FontAwesomeIcon icon={faTwitter} />
                        </a>
                        <a href={member.instagram} className="icon-link" style={styles.icon}>
                            <FontAwesomeIcon icon={faInstagram} />
                        </a>
                        <a href={member.linkedin} className="icon-link" style={styles.icon}>
                            <FontAwesomeIcon icon={faLinkedin} />
                        </a>
                    </div>
                </div>
        ))}
    </div>
);

export default ModernTeamTemplate;
