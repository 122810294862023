import React from 'react';
import { Form, Button } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

const FAQForm = ({ formValues, setFormValues, handleAddJsonArrayItem, handleRemoveJsonArrayItem }) => {
    const renderJsonArrayFields = (fieldName, itemSchema) => (
        <>
            {formValues[fieldName]?.map((item, index) => (
                <div key={index} className="mb-3 carousel-wrap">
                    {Object.keys(itemSchema).map((key) => (
                        <Form.Group key={key} className="mb-3">
                            <Form.Label>
                                {key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1')}:
                            </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={`Enter ${key}`}
                                value={item[key]}
                                onChange={(e) => {
                                    const updatedItems = [...formValues[fieldName]];
                                    updatedItems[index][key] = e.target.value;
                                    setFormValues({ ...formValues, [fieldName]: updatedItems });
                                }}
                            />
                        </Form.Group>
                    ))}
                    <Button variant="danger" className="mt-2" onClick={() => handleRemoveJsonArrayItem(fieldName, index)}>
                        Remove
                    </Button>
                </div>
            ))}
            <Button variant="primary" onClick={() => handleAddJsonArrayItem(fieldName, itemSchema)} className="add-more-media">
                <FontAwesomeIcon icon={faPlus} /> Add More
            </Button>
        </>
    );

    return <>{renderJsonArrayFields('faqs', { question: '', answer: '' })}</>;
};

export default FAQForm;
