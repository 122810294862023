import React from 'react';
import './ImageTemplates.css'; // Import CSS file if needed

function ImageComponent({ imgSrc, styles = {}, template = "Default" }) {
    const containerClassName = `${template.toLowerCase()}-image`; // Class for parent div based on template
    const imageStyles = styles?.image || {}; // Inline styles for the image

    // Wrap image in a link if the `link` property is present
    const imageElement = (
        <img 
            src={imgSrc} 
            alt="Dynamic Content" 
            style={imageStyles} 
        />
    );

    return (
        <div className={containerClassName}> {/* Center alignment if no textAlign provided */}
            {styles.link ? (
                <a href={styles.link} target="_blank" rel="noopener noreferrer">
                    {imageElement}
                </a>
            ) : (
                imageElement
            )}
        </div>
    );
}

export default ImageComponent;

// Configuration for image templates
export const imageTemplateConfig = [
    {
        templateName: 'Default',
        previewImage: 'https://www.fiklu.com/media/default-image',
        styles: {
            image: {
                maxWidth: '100%',
                height: 'auto',
                borderRadius: '0',
                boxShadow: 'none',
                border: 'none',
            },
        },
    },
    {
        templateName: 'Rounded',
        previewImage: 'https://www.fiklu.com/media/rounded-image',
        styles: {
            image: {
                maxWidth: '100%',
                height: 'auto',
                borderRadius: '50%',
                boxShadow: 'none',
                border: '2px solid #ccc',
            },
        },
    },
    {
        templateName: 'Shadow',
        previewImage: 'https://www.fiklu.com/media/shadow-image',
        styles: {
            image: {
                maxWidth: '100%',
                height: 'auto',
                boxShadow: '5px 5px 15px rgba(0, 0, 0, 0.3)',
                borderRadius: '10px',
            },
        },
    },
    {
        templateName: 'Framed',
        previewImage: 'https://www.fiklu.com/media/framed-image',
        styles: {
            image: {
                maxWidth: '100%',
                height: 'auto',
                border: '5px solid #333',
                padding: '10px',
                backgroundColor: '#f9f9f9',
            },
        },
    },
    {
        templateName: 'Polaroid',
        previewImage: 'https://www.fiklu.com/media/polaroid-image',
        styles: {
            image: {
                maxWidth: '90%',
                height: 'auto',
                border: '1px solid #ccc',
                padding: '15px',
                backgroundColor: '#fff',
                boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.5)',
                transform: 'rotate(-3deg)',
            },
        },
    },
    {
        templateName: 'Circle-Frame',
        previewImage: 'https://www.fiklu.com/media/circle-frame-image',
        styles: {
            image: {
                maxWidth: '50%',
                height: 'auto',
                borderRadius: '50%',
                border: '5px solid #555',
                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
            },
        },
    },
];
