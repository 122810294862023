import React from 'react';
import { Form, Button, Image } from 'react-bootstrap';
import MediaLibrary from '../MediaLibrary';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

const TeamSectionForm = ({ formValues, setFormValues, handleAddJsonArrayItem, handleRemoveJsonArrayItem }) => {
    const renderJsonArrayFields = (fieldName, itemSchema) => (
        <>
            {formValues[fieldName]?.map((member, index) => (
                <div key={index} className="carousel-wrap">
                    <div className="row">
                        {/* Media Library for Image */}
                        <div className="col-md-3">
                            <div className="media-img-wrap">
                                {member.image ? (
                                    <Image
                                        src={member.image}
                                        alt={member.name || 'Team Member'}
                                        thumbnail
                                        style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                                    />
                                ) : (
                                    <div
                                        style={{
                                            width: '100px',
                                            height: '100px',
                                            backgroundColor: '#ddd',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            margin: 'auto',
                                        }}
                                    >
                                        No Image
                                    </div>
                                )}

                                <MediaLibrary
                                    onSelectImage={(url) => {
                                        const updatedTeamMembers = [...formValues[fieldName]];
                                        updatedTeamMembers[index].image = url;
                                        setFormValues({ ...formValues, [fieldName]: updatedTeamMembers });
                                    }}
                                />
                            </div>
                        </div>

                        {/* Name and Position */}
                        <div className="col-md-9">
                            <div className="mb-3">
                                <label className="form-label">Team member name</label>
                                <Form.Control
                                    type="text"
                                    placeholder="Name"
                                    value={member.name || ''}
                                    onChange={(e) => {
                                        const updatedTeamMembers = [...formValues[fieldName]];
                                        updatedTeamMembers[index].name = e.target.value;
                                        setFormValues({ ...formValues, [fieldName]: updatedTeamMembers });
                                    }}
                                    className="mb-2"
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Team member position</label>
                                <Form.Control
                                    type="text"
                                    placeholder="Position"
                                    value={member.position || ''}
                                    onChange={(e) => {
                                        const updatedTeamMembers = [...formValues[fieldName]];
                                        updatedTeamMembers[index].position = e.target.value;
                                        setFormValues({ ...formValues, [fieldName]: updatedTeamMembers });
                                    }}
                                    className="mb-2"
                                />
                            </div>
                        </div>
                    </div>
                    <Button
                        variant="danger"
                        className="mt-2"
                        onClick={() => handleRemoveJsonArrayItem(fieldName, index)}
                    >
                        Remove Member
                    </Button>
                </div>
            ))}
            <Button
                variant="primary"
                className="add-more-media"
                onClick={() => handleAddJsonArrayItem(fieldName, { image: '', name: '', position: '' })}
            >
                <FontAwesomeIcon icon={faPlus} /> Add Member
            </Button>
        </>
    );

    return (
        <div>
            {/* Headline Input */}
            

            {/* Team Members */}
            {renderJsonArrayFields('teamMembers', { image: '', name: '', position: '' })}
        </div>
    );
};

export default TeamSectionForm;
