import React from 'react';
import './ButtonTemplates.css'; // Import CSS file

function ButtonComponent({
  text,
  styles = {},
  template = "Default",
}) {
  
// Extract button and container styles
const buttonStyles = styles?.buttonStyles || {};
const containerStyles = styles?.containerStyles || {};

  const containerClassName = `${template.toLowerCase()}-button`; // Class for the button template

  // Handle the button link
  const handleClick = () => {
    if (buttonStyles.link) {
      window.open(buttonStyles.link, '_blank');
    }
  };

  return (
    <div style={containerStyles} className="button-container">
      <button
        style={buttonStyles}
        onClick={handleClick}
        className={`btn ${containerClassName}`}
      >
        {text || "Click Me"}
      </button>
    </div>
  );
}

export default ButtonComponent;
