import React from 'react';
import './ClassicProgress.css';

const ClassicProgress = ({ items, styles = {} }) => {
    const titleStyles = styles.title || {};
    const barStyles = styles.bar || {};
    const fillStyles = styles.fill || {};
    const percentageStyles = styles.percentage || {};

    return (
        <div className="progress-container classic-progress">
            {items.map((item, index) => (
                <div key={index} className="progress-item">
                    <div className="progress-title" style={titleStyles}>
                        {item.title}
                    </div>
                    <div className="progress-bar" style={barStyles}>
                        <div
                            className="progress-fill"
                            style={{
                                ...fillStyles,
                                width: `${item.percentage}%`,
                            }}
                        >
                            
                            <div className="progress-percentage" style={percentageStyles}>
                                {item.percentage}%
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ClassicProgress;
