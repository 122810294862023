import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faCheck } from '@fortawesome/free-solid-svg-icons';
import StyleEditor from './StyleEditor';

const StyleEditorModal = ({ show, handleClose, currentTarget, onSave }) => {
    const [styles, setStyles] = React.useState(currentTarget?.styles || {});

    React.useEffect(() => {
        setStyles(currentTarget?.styles || {}); // Reset styles when modal is reopened
    }, [currentTarget]);

    const handleSave = () => {
        onSave(styles); // Save updated styles
        handleClose(); // Close modal
    };

    return (
        <Modal className="model-styling" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Row Style</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ padding: '20px', overflowY: 'auto', maxHeight: '60vh' }}>
                <StyleEditor
                    styles={styles}
                    onChange={setStyles}
                    editableFields={['backgroundColor', 'border', 'color', 'width', 'height', 'margin', 'padding']}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={handleClose}>
                    <FontAwesomeIcon icon={faXmark} /> Close
                </Button>
                <Button variant="success" onClick={handleSave}>
                    <FontAwesomeIcon icon={faCheck} /> Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default StyleEditorModal;
