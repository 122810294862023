import React from 'react';
import ClassicProgress from './templates/progress/ClassicProgress';
import ModernProgress from './templates/progress/ModernProgress';
import MinimalProgress from './templates/progress/MinimalProgress';

function ProgressComponent({ items, template,  styles = {}  }) {
    switch (template) {
        case 'Modern':
            return <ModernProgress items={items} styles={styles} />;
        case 'Minimal':
            return <MinimalProgress items={items} styles={styles} />;
        case 'Classic':
        default:
            return <ClassicProgress items={items} styles={styles} />;
    }
}

export default ProgressComponent;
