import React from 'react';
import './ClassicHeaderFooterCard.css';

const ClassicHeaderFooterCard = ({ header, footer, title, bodyText, styles = {} }) => {
    return (
        <div className="card classic-hf-card">
            <div className="card-header classic-header" style={styles.header || {}}>
                {header}
            </div>
            <div className="card-body">
                <h5 className="card-title" style={styles.title || {}}>
                    {title}
                </h5>
                <p className="card-text" style={styles.bodyText || {}}>
                    {bodyText}
                </p>
                <a href={styles.button?.link || "#"} className="btn btn-primary" style={styles.button || {}}>
                    {styles.button?.text || "Read More"}
                </a>
            </div>
            <div className="card-footer classic-footer" style={styles.footer || {}}>
                {footer}
            </div>
        </div>
    );
};

export default ClassicHeaderFooterCard;
