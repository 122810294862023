import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './IconCardTemplates.css'; // Custom CSS for icon card templates

const IconCard = ({ icon, title, description, template = "Classic", styles = {} }) => {
    switch (template) {
        case "Modern":
            return (
                <div className="card icon-card-modern" style={styles.container || {}}>
                    <div className="card-body text-center">
                        <div
                            className="icon-wrap"
                            style={{
                                backgroundColor: styles.iconBackground,
                                display: 'inline-block',
                            }}
                        >
                            <FontAwesomeIcon
                                icon={icon}
                                size="2x"
                                style={styles.icon || {}}
                            />
                        </div>
                        <h5 className="card-title mt-3" style={styles.title || {}}>
                            {title}
                        </h5>
                        <p className="card-text" style={styles.description || {}}>
                            {description}
                        </p>
                        <a
                            href={styles.button?.link || "#"}
                            className="btn btn-primary"
                            style={styles.button || {}}
                        >
                            {styles.button?.text || "Read More"}
                        </a>
                    </div>
                </div>
            );

        case "Minimal":
            return (
                <div className="card icon-card-minimal text-left" style={styles.container || {}}>
                    <div className="d-flex align-items-center">
                        <FontAwesomeIcon
                            icon={icon}
                            size="2x"
                            style={styles.icon || { marginRight: '10px' }}
                        />
                        <h5 className="card-title" style={styles.title || {}}>
                            {title}
                        </h5>
                    </div>
                    <p className="card-text mt-2" style={styles.description || {}}>
                        {description}
                    </p>
                </div>
            );

        default: // Classic
            return (
                <div className="card icon-card-classic text-center" style={styles.container || {}}>
                    <div className="card-body">
                        <FontAwesomeIcon
                            icon={icon}
                            size="2x"
                            className="mb-3"
                            style={styles.icon || {}}
                        />
                        <h5 className="card-title" style={styles.title || {}}>
                            {title}
                        </h5>
                        <p className="card-text" style={styles.description || {}}>
                            {description}
                        </p>
                    </div>
                </div>
            );
    }
};

export default IconCard;
