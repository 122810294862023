import React from 'react';
import ClassicHeaderFooterCard from './templates/headerFooterCard/ClassicHeaderFooterCard';
import ModernHeaderFooterCard from './templates/headerFooterCard/ModernHeaderFooterCard';
import MinimalHeaderFooterCard from './templates/headerFooterCard/MinimalHeaderFooterCard';

const HeaderFooterCardComponent = ({ template = "Classic", ...props }) => {
    switch (template) {
        case "Modern":
            return <ModernHeaderFooterCard {...props} />;
        case "Minimal":
            return <MinimalHeaderFooterCard {...props} />;
        default:
            return <ClassicHeaderFooterCard {...props} />;
    }
};

export default HeaderFooterCardComponent;
