export const styleConfig = {
    featureCards: {
        title: ['fontFamily', 'fontSize', 'color', 'fontWeight'],
        description: ['fontFamily', 'fontSize', 'color', 'lineHeight'],
        button: ['fontSize', 'color', 'backgroundColor', 'borderRadius']
    },
    iconCard: {
        icon: ['color', 'fontSize', 'padding', 'margin'],
        title: ['fontFamily', 'fontSize', 'color', 'fontWeight', 'padding', 'margin'],
        description: ['fontFamily', 'fontSize', 'color', 'lineHeight', 'padding', 'margin'],
        button: ['fontSize', 'color', 'backgroundColor', 'border', 'padding', 'margin', 'borderRadius'],
        container: [
            'backgroundColor',
            'border',
            'borderRadius',
            'padding',
            'margin',
        ],

    },
    heading: {
        text: [
            'fontFamily', 'fontSize', 'color', 'textAlign', 'margin', 'padding', 
            'fontWeight', 'fontStyle', 'lineHeight', 'letterSpacing', 'border'
        ]
    },
    image: {
        imageStyles: [
            'width', 'height', 'borderRadius', 'borderColor', 'borderWidth',
            'margin', 'padding', 'alignment', 'sizing', 'link'
        ]
    },
    gallery: {
        image: [
            'width', 'height', 'borderRadius', 'borderColor', 'borderWidth',
            'margin', 'padding', 'alignment', 'sizing', 'link'
        ]
    },
    faq: {
        title: ['fontFamily', 'fontSize', 'color', 'textAlign', 'margin'],
        question: ['fontFamily', 'fontSize', 'color', 'fontWeight'],
        answer: ['fontFamily', 'fontSize', 'color', 'lineHeight', 'backgroundColor', 'padding']
    },
    socialMediaIcons: {
        container: ['display', 'justifyContent', 'alignItems', 'gap'],
        icon: ['color', 'fontSize', 'margin']
    },
    text: {  // Configuration for TextComponent
        content: [
            'fontFamily', 'fontSize', 'color', 'textAlign', 'margin', 'padding', 
            'fontWeight', 'fontStyle', 'lineHeight', 'letterSpacing', 'textDecoration',
        ]
    },    
      button: {
        buttonStyles: [
          'backgroundColor', 'link', 'border', 'borderColor', 'borderRadius',
          'fontFamily', 'fontSize', 'color', 'letterSpacing', 'lineHeight',
          'margin', 'padding', 'display', 'justifyContent', 'alignItems', 'gap',
        ],
        containerStyles: [
          'backgroundColor', 'padding', 'margin', 'textAlign', 'borderRadius',
          'boxShadow', 'width', 'height', 'display', 'alignItems', 'justifyContent',
        ],
      },
    cardComponent: {
        title: [
            'fontFamily', 'fontSize', 'color', 'fontWeight', 'textAlign', 
            'margin', 'padding', 'letterSpacing', 'lineHeight'
        ],
        image: [
            'width', 'height', 'borderRadius', 'borderColor', 'borderWidth',
            'margin', 'padding', 'alignment', 'sizing'
        ],
        bodyText: [
            'fontFamily', 'fontSize', 'color', 'lineHeight', 'textAlign', 
            'margin', 'padding', 'letterSpacing', 'fontWeight'
        ]
    },
    imageOverlayCard: {
        title: [
            'fontFamily', 'fontSize', 'color', 'fontWeight', 'textAlign', 
            'margin', 
            'letterSpacing', 'lineHeight'
        ],
        bodyText: [
            'fontFamily', 'fontSize', 'color', 'fontWeight', 'textAlign', 
            'lineHeight', 'letterSpacing', 'margin'
        ],
        image: [
            'height', 'width', 'opacity', 
        ],
        button: [
            'backgroundColor', 'color', 'fontFamily', 'fontSize', 'fontWeight', 
            'padding', 
            'borderRadius', 'letterSpacing', 'lineHeight'
        ]
    },
    headerFooterCard: {
        header: ['fontFamily', 'fontSize', 'color',  'backgroundColor', 'fontWeight', 'textAlign', 'margin', 'padding'],
        footer: ['fontFamily',  'fontSize', 'color', 'backgroundColor', 'fontWeight', 'textAlign', 'margin', 'padding'],
        title: ['fontFamily', 'fontSize', 'color', 'fontWeight', 'textAlign', 'margin', 'padding'],
        bodyText: ['fontFamily', 'fontSize', 'color', 'fontWeight', 'textAlign', 'lineHeight', 'letterSpacing', 'margin', 'padding'],
        button: [
            'backgroundColor', 'color', 'fontFamily', 'fontSize', 'fontWeight', 
            'borderRadius', 'padding', 'margin', 'border', 'letterSpacing', 
            'lineHeight', 'link', 'text'
        ] 
    },
    horizontalCard: {
        title: [
            'fontFamily', 'fontSize', 'color', 'fontWeight', 'textAlign', 
            'margin', 'padding', 'letterSpacing', 'lineHeight'
        ],
        bodyText: [
            'fontFamily', 'fontSize', 'color', 'fontWeight', 'textAlign', 
            'lineHeight', 'letterSpacing', 'margin', 'padding'
        ],
        image: [
            'width', 'height', 'borderRadius', 'borderColor', 'borderWidth', 
            'margin', 'padding', 'alignment', 'sizing'
        ],
        button: [
            'backgroundColor', 'color', 'fontFamily', 'fontSize', 'fontWeight', 
            'padding', 'borderRadius', 'letterSpacing', 'lineHeight', 'border', 'link'
        ]
    },
    pricingTable: {
        planName: [
            'fontFamily', 'fontSize', 'color', 'fontWeight', 'textAlign', 
            'margin', 'padding', 'letterSpacing', 'lineHeight'
        ],
        price: [
            'fontFamily', 'fontSize', 'color', 'fontWeight', 'textAlign', 
            'letterSpacing', 'lineHeight', 'margin', 'padding'
        ],
        features: [
            'fontFamily', 'fontSize', 'color', 'fontWeight', 'lineHeight', 
            'letterSpacing', 'textAlign', 'margin', 'padding'
        ],
        button: [
            'backgroundColor', 'color', 'fontFamily', 'fontSize', 'fontWeight', 
            'padding', 'borderRadius', 'letterSpacing', 'lineHeight', 'border', 'link'
        ]
    },
    testimonialComponent: {
        /*headline: [
            'fontFamily', 'fontSize', 'color', 'textAlign', 'fontWeight',  
             'letterSpacing', 'lineHeight','margin', 'padding',   
        ],
        description: [
            'fontFamily', 'fontSize', 'color', 'textAlign', 'fontWeight',
            'letterSpacing', 'lineHeight','margin', 'padding',   
        ],*/
        image: [
            'width', 'height', 'alignment', 'borderRadius',  'borderColor', 'borderWidth', 
            'margin', 
        ],
        name: [
            'fontFamily', 'fontSize', 'color', 'textAlign', 'fontWeight', 
            'letterSpacing', 'lineHeight','margin', 'padding', 
        ],
        position: [
            'fontFamily', 'fontSize', 'color', 'textAlign', 'fontWeight',  
             'letterSpacing', 'lineHeight','margin', 'padding',   
        ],
        message: [
            'fontFamily', 'fontSize', 'color', 'textAlign', 'fontWeight',  
             'letterSpacing', 'lineHeight','margin', 'padding',
        ]
    },

    // ...other style configurations
    blockquote: {
        blockquote: [
            'fontFamily', 'fontSize', 'color', 'fontStyle', 'textAlign', 'borderLeftWidth',
            'borderLeftColor', 'lineHeight'
        ],
        footer: [
            'fontFamily', 'fontSize', 'color', 'fontStyle', 'textAlign','lineHeight'
        ]
    },
    divider: {
        divider: [
            'borderColor', 'borderWidth', 'borderStyle',
            'margin', 'width', 'height'
        ]
    },
    alert: {
        alertStyle: [
            'backgroundColor', 'color', 'fontSize', 'fontWeight',
            'padding', 'margin', 'borderRadius', 'border',
            'boxShadow', 'textAlign', 'lineHeight', 'letterSpacing'
        ]
    },
    video: {
        videoStyle: [
            'width', 'height', 'borderRadius',
            'margin', 'padding', 'backgroundColor', 
        ]
    },    
    badge: { badgeStyle: [
            'backgroundColor', 'color', 'fontSize', 'padding', 'margin',
            'borderRadius', 'fontWeight', 'boxShadow', 'textAlign', 'lineHeight',
            'letterSpacing'
        ]
    },
    spacer: {
        spacerStyle: [
            'height', 'backgroundColor', 'width', 'margin', 
            'padding', 'borderRadius', 'border', 'boxShadow', 'opacity'
        ]
    },
    teamSection: {
        card: [
            "boxShadow",
            "borderRadius",
            "padding",
            "margin",
            "backgroundColor",
        ],
        image: [
            "height",
            "backgroundSize",
            "backgroundPosition",
            "borderRadius",
        ],
        name: ["color", "fontSize", "fontWeight", "textAlign"],
        position: ["color", "fontSize", "fontWeight", "textAlign"],
        Container: ["margin", "padding", "textAlign"],
        icon: ["color", "fontSize", "margin", "padding"],
    },
    featureCards: {
        headline: ["color", "fontSize", "fontWeight", "textAlign", "margin"],
        description: ["color", "fontSize", "fontWeight", "textAlign", "margin"],
        card: ["border", "boxShadow", "borderRadius", "padding", "margin"],
        image: ["borderRadius", "objectFit", "width", "height"],
        title: ["color", "fontSize", "fontWeight", "textAlign"],
        text: ["color", "fontSize", "lineHeight", "textAlign"],
    },
    tabs: {
        tabContainer: [
            "backgroundColor", "border", "borderRadius", "padding", "margin",
        ],
        tabItem: [
            "fontFamily", "fontSize", "color", "fontWeight", "textAlign", "backgroundColor",
            "padding", "margin", "border", "borderRadius",
        ],
        tabItemActive: [
            "fontFamily", "fontSize", "color", "fontWeight", "textAlign", "backgroundColor",
            "padding", "margin", "border", "borderRadius",
        ],
        tabContent: [
            "backgroundColor", "padding", "margin", "border", "borderRadius",
            "color", "fontSize", "fontWeight", "lineHeight",
        ],
    },
    accordion: {
        header: ['backgroundColor', 'color', 'fontSize', 'fontWeight', 'padding', 'margin', 'border'],
        button: ['backgroundColor', 'color', 'fontSize', 'fontWeight', 'padding', 'margin', 'borderRadius'],
        body: ['backgroundColor', 'color', 'fontSize', 'lineHeight', 'padding', 'margin', 'border'],
    },
    listGroupCard: {
        header: [
            'fontFamily', 'fontSize', 'fontWeight', 'color', 
            'backgroundColor', 'padding', 'margin', 'border', 'textAlign'
        ],
        item: [
            'fontFamily', 'fontSize', 'fontWeight', 'color', 
            'backgroundColor', 'padding', 'margin', 'border', 'textAlign', 'lineHeight'
        ],
        icon: [
            'color', 'fontSize', 'margin', 'padding'
        ],
    },
    modal: {
        title: [
            'fontFamily', 'fontSize', 'fontWeight', 'color', 
            'textAlign', 'margin', 'padding', 'lineHeight', 'letterSpacing'
        ],
        body: [
            'fontFamily', 'fontSize', 'color', 'lineHeight', 
            'padding', 'margin', 'textAlign', 'letterSpacing'
        ],
        button: [
            'backgroundColor', 'color', 'fontSize', 'fontWeight', 
            'borderRadius', 'padding', 'margin', 'border', 'boxShadow', 'hoverColor'
        ],
        closeButton: [
            'color', 'fontSize', 'border', 'borderRadius', 
            'backgroundColor', 'hoverColor'
        ],
        overlay: [
            'backgroundColor', 'opacity', 'padding', 'margin'
        ],
    },
    timeline: {
        date: ['fontFamily', 'fontSize', 'color', 'fontWeight', 'textAlign', 'margin', 'padding'],
        heading: ['fontFamily', 'fontSize', 'color', 'fontWeight', 'textAlign', 'margin'],
        body: ['fontFamily', 'fontSize', 'color', 'lineHeight', 'textAlign', 'margin'],
        panel: ['backgroundColor', 'border', 'borderRadius', 'padding', 'boxShadow', 'margin'],
    },
    progress: {
        title: ['fontFamily', 'fontSize', 'color', 'fontWeight'],
        bar: ['backgroundColor', 'borderRadius', 'height'],
        fill: ['backgroundColor', 'borderRadius', 'transition'],
        percentage: ['fontFamily', 'fontSize', 'color', 'fontWeight'],
    },
    breadcrumb: {
        container: [
            'backgroundColor', 'padding', 'margin', 'borderRadius', 
            'border', 'boxShadow', 'textAlign', 'display', 'justifyContent'
        ],
        item: [
            'fontFamily', 'fontSize', 'fontWeight', 'color', 
            'textDecoration', 'padding', 'margin', 'lineHeight'
        ],
        separator: [
            'color', 'fontSize', 'fontWeight', 'margin', 'padding'
        ],
        activeItem: [
            'fontFamily', 'fontSize', 'fontWeight', 'color', 
            'textDecoration', 'padding', 'margin', 'lineHeight'
        ],
    },
    carousel: {
        container: [
            'backgroundColor',
            'padding',
            'margin',
            'border',
            'borderRadius',
            'boxShadow',
            'width',
            'height',
        ],
        image: [
            'width',
            'height',
            'borderRadius',
            'objectFit',
            'margin',
            'padding',
        ],
        caption: [
            'fontFamily',
            'fontSize',
            'color',
            'textAlign',
            'backgroundColor',
            'padding',
            'margin',
            'lineHeight',
        ],
        altText: [ // New section for alt text styles
            'fontFamily',
            'fontSize',
            'color',
            'fontWeight',
            'textAlign',
            'margin',
            'padding',
            'lineHeight',
        ],
        button: [
            'backgroundColor',
            'color',
            'fontSize',
            'padding',
            'margin',
            'borderRadius',
            'border',
            'fontWeight',
        ],
        indicators: [
            'backgroundColor',
            'borderRadius',
            'width',
            'height',
            'margin',
        ],
        control: [
            'color',
            'fontSize',
            'backgroundColor',
            'borderRadius',
            'padding',
            'margin',
        ],
    },
contactForm: {
        container: [
            'backgroundColor', 'padding', 'margin', 'border', 'borderRadius', 
            'boxShadow', 'width', 'height', 'display', 'alignItems', 'justifyContent'
        ],
        labels: [
            'fontFamily', 'fontSize', 'color', 'fontWeight', 'margin', 'padding', 'textAlign'
        ],
        inputs: [
            'fontFamily', 'fontSize', 'color', 'backgroundColor', 'border', 'borderRadius', 
            'padding', 'margin', 'width', 'height', 'boxShadow', 'textAlign'
        ],
        button: [
            'backgroundColor', 'color', 'fontSize', 'fontWeight', 'border', 'borderRadius', 
            'padding', 'margin', 'boxShadow', 'width', 'height', 'textAlign'
        ],
        buttonText: [
            'fontFamily', 'fontSize', 'color', 'fontWeight', 'textTransform', 'letterSpacing'
        ]
    }
};
