import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './HorizontalCardTemplates.css'; // CSS for templates

const HorizontalCard = ({ title, imageUrl, bodyText, button, styles = {}, template = "Default" }) => {
    switch (template) {
        case "HorizontalCardLeft":
            return (
                <div className="card horizontal-card-left mb-3">
                    <div className="row g-0">
                        <div className="col-md-8">
                            <div className="card-body" style={styles.body}>
                                <h5 className="card-title" style={styles.title}>{title}</h5>
                                <p className="card-text" style={styles.bodyText}>{bodyText}</p>
                                <a href="#" className="btn btn-primary" style={styles.button}>
                                    {button?.text || 'Learn More'}
                                </a>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <img
                                src={imageUrl}
                                className="img-fluid"
                                alt="Split with Border"
                                style={styles.image}
                            />
                        </div>
                    </div>
                </div>
            );

        case "HorizontalCardTop":
            return (
                <div className="card horizontal-card-top mb-3">
                    <div className="row g-0">
                        <div className="col-md-12 card-img-h-t">
                            <img
                                src={imageUrl}
                                className="img-fluid"
                                alt="Modern Outline"
                                style={styles.image}
                            />
                        </div>
                        <div className="col-md-12">
                            <div className="card-body" style={styles.body}>
                                <h5 className="card-title" style={styles.title}>{title}</h5>
                                <p className="card-text" style={styles.bodyText}>{bodyText}</p>
                                <a href="#" className="btn btn-primary" style={styles.button}>
                                    {button?.text || 'Read More'}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            );

        case "HorizontalCardBottom":
            return (
                <div className="card horizontal-card-bottom mb-3">
                    <div className="row g-0">
                        <div className="col-md-12">
                            <div className="card-body" style={styles.body}>
                                <h5 className="card-title" style={styles.title}>{title}</h5>
                                <p className="card-text" style={styles.bodyText}>{bodyText}</p>
                                <a href="#" className="btn btn-primary" style={styles.button}>
                                    {button?.text || 'Read More'}
                                </a>
                            </div>
                        </div>
                        <div className="col-md-12 card-img-h-t">
                            <img
                                src={imageUrl}
                                className="img-fluid"
                                alt="Modern Outline"
                                style={styles.image}
                            />
                        </div>
                    </div>
                </div>
            );

        case "HorizontalCardRoundedLeft":
            return (
                <div className="card horizontal-card-rounded-Left mb-3">
                    <div className="row g-0">
                        <div className="col-md-4 card-img-r-l">
                            <img
                                src={imageUrl}
                                className="img-fluid "
                                alt="Framed with Background"
                                style={styles.image}
                            />
                        </div>
                        <div className="col-md-8">
                            <div className="card-body" style={styles.body}>
                                <h5 className="card-title" style={styles.title}>{title}</h5>
                                <p className="card-text" style={styles.bodyText}>{bodyText}</p>
                                <a href="#" className="btn btn-primary" style={styles.button}>
                                    {button?.text || 'Get Started'}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            );

        case "HorizontalCardRoundedTop":
            return (
                <div className="card horizontal-card-rounded-top mb-3">
                    <div className="row g-0">
                        <div className="col-md-12 card-img-r-l">
                            <img
                                src={imageUrl}
                                className="img-fluid shadow-sm"
                                alt="Image with Shadow"
                                style={styles.image}
                            />
                        </div>
                        <div className="col-md-12">
                            <div className="card-body" style={styles.body}>
                                <h5 className="card-title" style={styles.title}>{title}</h5>
                                <p className="card-text" style={styles.bodyText}>{bodyText}</p>
                                <a href="#" className="btn btn-primary" style={styles.button}>
                                    {button?.text || 'Explore'}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            );

        default: // Default layout
            return (
                <div className="card horiz-default-card mb-3">
                    <div className="row g-0">
                        <div className="col-md-4">
                            <img
                                src={imageUrl}
                                className="img-fluid rounded-start"
                                alt="Default"
                                style={styles.image}
                            />
                        </div>
                        <div className="col-md-8">
                            <div className="card-body" style={styles.body}>
                                <h5 className="card-title" style={styles.title}>{title}</h5>
                                <p className="card-text" style={styles.bodyText}>{bodyText}</p>
                                <a href="#" className="btn btn-primary" style={styles.button}>
                                    {button?.text || 'Go somewhere'}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            );
    }
};

export default HorizontalCard;
