import React, { useEffect, useRef } from 'react';
import Masonry from 'masonry-layout';
import './MasonryTemplate.css';

const MasonryTemplate = ({ images, styles }) => {
    const masonryRef = useRef(null);

    useEffect(() => {
        // Initialize Masonry
        const masonryInstance = new Masonry(masonryRef.current, {
            itemSelector: '.masonrygallery-item',
            columnWidth: '.masonrygallery-item',
            gutter: 10, // Adjust spacing
            percentPosition: true,
        });

        // Cleanup on unmount
        return () => masonryInstance.destroy();
    }, []);

    return (
        <div ref={masonryRef} className="gallery-masonry">
            {images.map((img, idx) => (
                <div
                    key={idx}
                    className="masonrygallery-item masonry"
                    style={{
                        ...styles.image,
                        backgroundImage: `url(${img.src})`,
                    }}
                />
            ))}
        </div>
    );
};

export default MasonryTemplate;
