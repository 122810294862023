import React, { useState } from 'react';
import { Form } from 'react-bootstrap';

const VideoForm = ({ formValues, setFormValues }) => {
    const [errorMessage, setErrorMessage] = useState('');

    // Function to validate the URL
    const validateUrl = (url) => {
        const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/)/;
        const vimeoRegex = /^(https?:\/\/)?(www\.)?vimeo\.com\/\d+/;
        return youtubeRegex.test(url) || vimeoRegex.test(url);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        // Validate URL
        if (value && !validateUrl(value)) {
            setErrorMessage('Invalid URL. Please provide a valid YouTube or Vimeo link.');
        } else {
            setErrorMessage('');
        }

        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    return (
        <div>
            <Form.Group controlId="formVideoSrc">
                <Form.Label>Video Source (YouTube or Vimeo)</Form.Label>
                <Form.Control
                    type="text"
                    name="src"
                    value={formValues.src || ''}
                    onChange={handleInputChange}
                    placeholder="Enter YouTube or Vimeo URL"
                    isInvalid={!!errorMessage}
                />
                <Form.Control.Feedback type="invalid">
                    {errorMessage}
                </Form.Control.Feedback>
                <Form.Text className="text-muted">
                    Please provide a valid video URL from YouTube (e.g., https://www.youtube.com/watch?v=dQw4w9WgXcQ)
                    or Vimeo (e.g., https://vimeo.com/123456789).
                </Form.Text>
            </Form.Group>
        </div>
    );
};

export default VideoForm;
