import React from 'react';
import './ModernHeaderFooterCard.css';

const ModernHeaderFooterCard = ({ header, footer, title, bodyText, styles = {} }) => {
    return (
        <div className="card modern-hf-card">
            <div className="card-header modern-header" style={styles.header || {}}>
                {header}
            </div>
            <div className="card-body">
                <h5 className="card-title" style={styles.title || {}}>
                    {title}
                </h5>
                <p className="card-text" style={styles.bodyText || {}}>
                    {bodyText}
                </p>
                <a href={styles.button?.link || "#"} className="btn modern-btn" style={styles.button || {}}>
                    {styles.button?.text || "Learn More"}
                </a>
            </div>
            <div className="card-footer modern-footer" style={styles.footer || {}}>
                {footer}
            </div>
        </div>
    );
};

export default ModernHeaderFooterCard;
