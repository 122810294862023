import React from 'react';

function SpacerComponent({ height = '20px', styles = {} }) {
    const spacerStyles = {
        height,
        ...styles.spacerStyle, // Apply additional styles dynamically
    };

    return <div className="spacer-wrap" style={spacerStyles}></div>;
}

export default SpacerComponent;
