import React from 'react';
import { Carousel } from 'react-bootstrap';

const ModernTemplate = ({ headline, description, testimonials, styles = {} }) => (
    <div className="testimonial-modern-wrap">
        <h2 style={styles.headline || {}}>{headline}</h2>
        <p style={styles.description || {}}>{description}</p>
        <Carousel indicators={false} interval={5000}>
            {testimonials.map((testimonial, idx) => (
                <Carousel.Item key={idx} className="testimonial-item-modern">
                    <div className="row align-items-center">
                        {/* Image Section */}
                        <div className="col-md-3 text-center">
                            <img
                                src={testimonial.image}
                                alt={testimonial.name}
                                className="rounded-circle img-fluid"
                                style={{
                                    width: "80px",
                                    height: "80px",
                                    ...styles.image,
                                }}
                            />
                        </div>
                        {/* Content Section */}
                        <div className="col-md-9">
                            <p style={styles.message || {}}>"{testimonial.message}"</p>
                            <h5 style={styles.name || {}}>{testimonial.name}</h5>
                            <h6 style={styles.position || {}}>{testimonial.position}</h6>
                        </div>
                    </div>
                </Carousel.Item>
            ))}
        </Carousel>
    </div>
);

export default ModernTemplate;
