import React from 'react';
import './MinimalProgress.css';

const MinimalProgress = ({ items }) => (
    <div className="progress-container minimal-progress">
        {items.map((item, index) => (
            <div key={index} className="progress-item">
                <div className="progress-bar">
                    <div
                        className="progress-fill"
                        style={{ width: `${item.percentage}%` }}
                    >
                        <span className="progress-title">{item.title}</span>
                    </div>
                </div>
            </div>
        ))}
    </div>
);

export default MinimalProgress;
