import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faCheck } from '@fortawesome/free-solid-svg-icons';

import { Modal, Button, Tabs, Tab, Form, InputGroup } from 'react-bootstrap';

import RichTextEditor from './pagebuilder/RichTextEditor';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import StyleEditor from './StyleEditor';
import { styleConfig } from '../config/styleConfig';
import { templateConfig } from '../config/templateConfig';


import IconCardForm from './componentForms/IconCardForm';
import TextForm from './componentForms/TextForm';
import HeadingForm from './componentForms/HeadingForm';
import ImageForm from './componentForms/ImageForm';
import ButtonForm from './componentForms/ButtonForm';
import BlockquoteForm from './componentForms/BlockquoteForm';
import RichTextForm from './componentForms/RichTextForm';
import CardComponentForm from './componentForms/CardComponentForm';
import ImageOverlayCardForm from './componentForms/ImageOverlayCardForm';
import HorizontalCardForm from './componentForms/HorizontalCardForm';
import HeaderFooterCardForm from './componentForms/HeaderFooterCardForm';
import DividerForm from './componentForms/DividerForm';
import ListForm from './componentForms/ListForm';
import AlertForm from './componentForms/AlertForm';
import AudioForm from './componentForms/AudioForm';
import IframeForm from './componentForms/IframeForm';
import VideoForm from './componentForms/VideoForm';
import TabsForm from './componentForms/TabsForm';
import AccordionForm from './componentForms/AccordionForm';
import CarouselForm from './componentForms/CarouselForm';
import ModalForm from './componentForms/ModalForm';
import SpacerForm from './componentForms/SpacerForm';
import BadgeForm from './componentForms/BadgeForm';
import GalleryForm from './componentForms/GalleryForm';
import PricingTableForm from './componentForms/PricingTableForm';
import MapForm from './componentForms/MapForm';
import FAQForm from './componentForms/FAQForm';
import SocialMediaIconsForm from './componentForms/SocialMediaIconsForm';
import CountdownTimerForm from './componentForms/CountdownTimerForm';
import LightboxGalleryForm from './componentForms/LightboxGalleryForm';
import TeamSectionForm from './componentForms/TeamSectionForm';
import FeatureCardsForm from './componentForms/FeatureCardsForm';
import TestimonialForm from './componentForms/TestimonialForm';
import ListGroupForm from './componentForms/ListGroupForm';
import ProgressForm from './componentForms/ProgressForm';
import BreadcrumbForm from './componentForms/BreadcrumbForm';
import ContactFormForm from './componentForms/ContactFormForm';
import ProductListingForm from './componentForms/ProductListingForm';

const ComponentEditorModal = ({ show, handleClose, component, saveChanges }) => {
  
    const [formValues, setFormValues] = useState({});

    // Set up default values to prevent errors
    const styleSections = component ? styleConfig[component.type] || {} : {};

   
    const [styles, setStyles] = useState({});
    const [activeTab, setActiveTab] = useState('content');

    const handleStyleChange = (section, newStyles) => {
        // Update styles state
        setStyles((prevStyles) => ({
            ...prevStyles,
            [section]: newStyles
        }));

        // Update formValues with the new styles
        setFormValues((prevValues) => ({
            ...prevValues,
            styles: {
                ...prevValues.styles,
                [section]: newStyles
            }
        }));
    };


    const formatLabel = (field) => {
        // Insert space before each uppercase letter and capitalize the first letter
        return field
            .replace(/([A-Z])/g, ' $1')
            .replace(/^./, (str) => str.toUpperCase());
    };

    useEffect(() => {
        if (component) {
            setFormValues(component.props);
            setStyles(component.props.styles || {});
        }
    }, [component]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value
        });
    };

    const handleIconChange = (icon) => {
        setFormValues({ ...formValues, icon });
    };

    const handleJsonArrayChange = (fieldName, index, key, value) => {
        const updatedArray = [...(formValues[fieldName] || [])];
        updatedArray[index] = { ...updatedArray[index], [key]: value };
        setFormValues({
            ...formValues,
            [fieldName]: updatedArray
        });
    };

    const handleAddJsonArrayItem = (fieldName, newItem) => {
        setFormValues({
            ...formValues,
            [fieldName]: [...(formValues[fieldName] || []), newItem]
        });
    };

    const handleRemoveJsonArrayItem = (fieldName, index) => {
        const updatedArray = (formValues[fieldName] || []).filter((_, i) => i !== index);
        setFormValues({
            ...formValues,
            [fieldName]: updatedArray
        });
    };

    const handleSave = () => {
        saveChanges(formValues);
    };

    const renderJsonArrayFields = (fieldName, itemSchema) => (
        <>
            {formValues[fieldName]?.map((item, index) => (
                <div key={index} className="mb-3">
                    {Object.keys(itemSchema).map((key) => (
                        <InputGroup key={key} className="mb-2">
                            <Form.Control
                                type="text"
                                placeholder={key}
                                value={item[key]}
                                onChange={(e) => handleJsonArrayChange(fieldName, index, key, e.target.value)}
                            />
                        </InputGroup>
                    ))}
                    <Button variant="danger" onClick={() => handleRemoveJsonArrayItem(fieldName, index)}>Remove</Button>
                </div>
            ))}
            <Button variant="primary" onClick={() => handleAddJsonArrayItem(fieldName, itemSchema)}>Add More</Button>
        </>
    );
    
    if (!component) return null; // Prevent rendering if `component` is null

    const templatesAvailable = templateConfig[component?.type]?.length > 0;
    
    return (
        <Modal className="model-styling" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Component "{component?.type}"</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Tabs activeKey={activeTab} onSelect={(tab) => setActiveTab(tab)}>
                    <Tab eventKey="content" title="Content">
                        <div className=''>
                            {component?.type === 'iconCard' && (
                                <IconCardForm formValues={formValues} setFormValues={setFormValues} />
                            )}
                            {component?.type === 'text' && (
                                <TextForm formValues={formValues} setFormValues={setFormValues} />
                            )}
                            {component?.type === 'heading' && (
                                <HeadingForm formValues={formValues} setFormValues={setFormValues} />
                            )}
                            {component?.type === 'image' && (
                                <ImageForm formValues={formValues} setFormValues={setFormValues} />
                            )}
                            {component?.type === 'button' && (
                                <ButtonForm formValues={formValues} setFormValues={setFormValues} />
                            )}
                            {component?.type === 'blockquote' && (
                                <BlockquoteForm formValues={formValues} setFormValues={setFormValues} />
                            )}
                            {component?.type === 'richText' && (
                                <RichTextForm formValues={formValues} setFormValues={setFormValues} />
                            )}
                            {component?.type === 'cardComponent' && (
                                <CardComponentForm formValues={formValues} setFormValues={setFormValues} />
                            )}
                            {component?.type === 'imageOverlayCard' && (
                                <ImageOverlayCardForm formValues={formValues} setFormValues={setFormValues} />
                            )}      
                            {component?.type === 'horizontalCard' && (
                                <HorizontalCardForm formValues={formValues} setFormValues={setFormValues} />
                            )}
                            {component?.type === 'headerFooterCard' && (
                                <HeaderFooterCardForm formValues={formValues} setFormValues={setFormValues} />
                            )}
                            {component?.type === 'divider' && (
                                <DividerForm formValues={formValues} setFormValues={setFormValues} />
                            )}
                            {component?.type === 'list' && (
                                <ListForm formValues={formValues} setFormValues={setFormValues} />
                            )}
                            {component?.type === 'alert' && (
                                <AlertForm formValues={formValues} setFormValues={setFormValues} />
                            )}
                            {component?.type === 'audio' && (
                                <AudioForm formValues={formValues} setFormValues={setFormValues} />
                            )}
                            {component?.type === 'iframe' && (
                                <IframeForm formValues={formValues} setFormValues={setFormValues} />
                            )}
                            {component?.type === 'accordion' && (
                                <AccordionForm
                                    formValues={formValues}
                                    setFormValues={setFormValues}
                                    handleAddJsonArrayItem={handleAddJsonArrayItem}
                                    handleRemoveJsonArrayItem={handleRemoveJsonArrayItem}
                                />
                            )}
                            {component?.type === 'carousel' && (
                                <CarouselForm
                                    formValues={formValues}
                                    setFormValues={setFormValues}
                                    handleAddJsonArrayItem={handleAddJsonArrayItem}
                                    handleRemoveJsonArrayItem={handleRemoveJsonArrayItem}
                                />
                            )}
                            {component?.type === 'tabs' && (
                                <TabsForm
                                    formValues={formValues}
                                    setFormValues={setFormValues}
                                    handleAddJsonArrayItem={handleAddJsonArrayItem}
                                    handleRemoveJsonArrayItem={handleRemoveJsonArrayItem}
                                />
                            )}
                            {component?.type === 'gallery' && (
                                <GalleryForm
                                    formValues={formValues}
                                    setFormValues={setFormValues}
                                    handleAddJsonArrayItem={handleAddJsonArrayItem}
                                    handleRemoveJsonArrayItem={handleRemoveJsonArrayItem}
                                />
                            )}
                            {component?.type === 'faq' && (
                                <FAQForm
                                    formValues={formValues}
                                    setFormValues={setFormValues}
                                    handleAddJsonArrayItem={handleAddJsonArrayItem}
                                    handleRemoveJsonArrayItem={handleRemoveJsonArrayItem}
                                />
                            )}
                            {component?.type === 'pricingTable' && (
                                <PricingTableForm
                                    formValues={formValues}
                                    setFormValues={setFormValues}
                                    handleAddJsonArrayItem={handleAddJsonArrayItem}
                                    handleRemoveJsonArrayItem={handleRemoveJsonArrayItem}
                                />
                            )}
                            {component?.type === 'socialMediaIcons' && (
                                <SocialMediaIconsForm
                                    formValues={formValues}
                                    setFormValues={setFormValues}
                                    handleAddJsonArrayItem={handleAddJsonArrayItem}
                                    handleRemoveJsonArrayItem={handleRemoveJsonArrayItem}
                                />
                            )}
                            {component?.type === 'lightboxGallery' && (
                                <LightboxGalleryForm
                                    formValues={formValues}
                                    setFormValues={setFormValues}
                                    handleAddJsonArrayItem={handleAddJsonArrayItem}
                                    handleRemoveJsonArrayItem={handleRemoveJsonArrayItem}
                                />
                            )}
                            {component?.type === 'teamSection' && (
                                <TeamSectionForm
                                    formValues={formValues}
                                    setFormValues={setFormValues}
                                    handleAddJsonArrayItem={handleAddJsonArrayItem}
                                    handleRemoveJsonArrayItem={handleRemoveJsonArrayItem}
                                />
                            )}
                            {component?.type === 'featureCards' && (
                                <FeatureCardsForm
                                    formValues={formValues}
                                    setFormValues={setFormValues}
                                    handleAddJsonArrayItem={handleAddJsonArrayItem}
                                    handleRemoveJsonArrayItem={handleRemoveJsonArrayItem}
                                />
                            )}
                            {component?.type === 'testimonialComponent' && (
                                <TestimonialForm
                                    formValues={formValues}
                                    setFormValues={setFormValues}
                                    handleAddJsonArrayItem={handleAddJsonArrayItem}
                                    handleRemoveJsonArrayItem={handleRemoveJsonArrayItem}
                                />
                            )}
                            {component?.type === 'video' && (
                                <VideoForm formValues={formValues} setFormValues={setFormValues} />
                            )}
                            {component?.type === 'badge' && (
                                <BadgeForm formValues={formValues} setFormValues={setFormValues} />
                            )}
                            {component?.type === 'productList' && (
                                <ProductListingForm formValues={formValues} setFormValues={setFormValues} />
                            )}

                            {component?.type === 'listGroupCard' && (
                                <ListGroupForm
                                    formValues={formValues}
                                    setFormValues={setFormValues}
                                    handleAddJsonArrayItem={handleAddJsonArrayItem}
                                    handleRemoveJsonArrayItem={handleRemoveJsonArrayItem}
                                />
                            )}
                            {component?.type === 'progress' && (
                                <ProgressForm
                                    formValues={formValues}
                                    setFormValues={setFormValues}
                                    handleAddJsonArrayItem={handleAddJsonArrayItem}
                                    handleRemoveJsonArrayItem={handleRemoveJsonArrayItem}
                                />
                            )}
                            {component?.type === 'breadcrumb' && (
                                <BreadcrumbForm
                                    formValues={formValues}
                                    setFormValues={setFormValues}
                                    handleAddJsonArrayItem={handleAddJsonArrayItem}
                                    handleRemoveJsonArrayItem={handleRemoveJsonArrayItem}
                                />
                            )}
                                              
                            {component?.type === 'contactForm' && (
                                <ContactFormForm
                                    formValues={formValues}
                                    setFormValues={setFormValues}
                                />
                            )}

                            {component?.type === 'timeline' && renderJsonArrayFields('events', { title: '', date: '', description: '' })}
                        </div>
                    </Tab>                   
                    <Tab eventKey="styles" title="Styles">
                        <Tabs defaultActiveKey={Object.keys(styleSections)[0] || 'default'}>
                            {Object.keys(styleSections).map((section) => (
                               
                                <Tab eventKey={section} title={formatLabel(section)} key={section}>
                                    <StyleEditor
                                        styles={styles[section] || {}} // Fallback to an empty object
                                        onChange={(newStyles) => handleStyleChange(section, newStyles)}
                                        editableFields={styleSections[section] || []} // Fallback to an empty array
                                    />
                                </Tab>
                            ))}
                        </Tabs>
                    </Tab>
                    {templatesAvailable && (
                        <Tab eventKey="templates" title="Templates">
                            <div className="template-selection">
                                {templateConfig[component?.type]?.map((template) => (
                                    <div
                                        key={template.templateName}
                                        className={`template-card ${
                                            formValues.template === template.templateName ? 'active' : ''
                                        }`}
                                        onClick={() =>
                                            setFormValues({ ...formValues, template: template.templateName })
                                        }
                                        style={{
                                            cursor: 'pointer',
                                            border:
                                                formValues.template === template.templateName
                                                    ? '2px solid #007bff'
                                                    : '1px solid #ddd',
                                            padding: '10px',
                                            margin: '10px',
                                            borderRadius: '5px',
                                        }}
                                    >
                                        <img
                                            src={template.previewImage}
                                            alt={template.templateName}
                                            style={{
                                                width: '100%',
                                                height: 'auto',
                                                borderRadius: '5px',
                                            }}
                                        />
                                        <p style={{ textAlign: 'center', marginTop: '5px' }}>
                                            {template.templateName}
                                        </p>
                                    </div>
                                ))}
                            </div>
                        </Tab>
                    )}

                </Tabs>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={handleClose}>
                    <FontAwesomeIcon icon={faXmark} />
                </Button>
                <Button variant="success" onClick={handleSave}>
                    <FontAwesomeIcon icon={faCheck} />
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ComponentEditorModal;
