import React from 'react';
import './PolaroidTemplate.css';

const PolaroidTemplate = ({ images }) => (
    <div className="gallery-polaroid">
        {images.map((img, idx) => (
            <div key={idx} className="polaroid-item">
                <img src={img.src} alt={`Polaroid image ${idx + 1}`} />
                <div className="caption">{img.caption || `Image ${idx + 1}`}</div>
            </div>
        ))}
    </div>
);

export default PolaroidTemplate;
