import React, { useState } from 'react';
import { Form, Button, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';

const ProgressForm = ({ formValues, setFormValues, handleAddJsonArrayItem, handleRemoveJsonArrayItem }) => {
    const renderJsonArrayFields = (fieldName, itemSchema) => (
        <>
            {formValues[fieldName]?.map((item, index) => (
                <div key={index} className="carousel-wrap mb-3">
                    <Form.Group className="mb-3">
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter title"
                            value={item.title || ''}
                            onChange={(e) => {
                                const updatedItems = [...formValues[fieldName]];
                                updatedItems[index].title = e.target.value;
                                setFormValues({ ...formValues, [fieldName]: updatedItems });
                            }}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Progress Percentage</Form.Label>
                        <InputGroup>
                            <Form.Control
                                type="number"
                                placeholder="Enter percentage"
                                value={item.percentage || ''}
                                onChange={(e) => {
                                    const updatedItems = [...formValues[fieldName]];
                                    updatedItems[index].percentage = e.target.value;
                                    setFormValues({ ...formValues, [fieldName]: updatedItems });
                                }}
                            />
                            <InputGroup.Text>%</InputGroup.Text>
                        </InputGroup>
                    </Form.Group>
                    <Button
                        variant="danger"
                        className="mt-2"
                        onClick={() => handleRemoveJsonArrayItem(fieldName, index)}
                    >
                        Remove
                    </Button>
                </div>
            ))}
            <Button
                variant="primary"
                 className="add-more-media"
                onClick={() => handleAddJsonArrayItem(fieldName, itemSchema)}
            >
                <FontAwesomeIcon icon={faPlus} /> Add More
            </Button>
        </>
    );

    return (
        <>
            {renderJsonArrayFields('items', { title: '', percentage: '' })}
        </>
    );
};

export default ProgressForm;
