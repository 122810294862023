import React from 'react';

function BadgeComponent({ text = 'Default Badge', variant = 'text-bg-secondary', styles = {} }) {
    return (
        <span
            className={`badge ${variant}`}
            style={styles.badgeStyle} // Apply dynamic styles
        >
            {text}
        </span>
    );
}

export default BadgeComponent;
