import React, { useState } from 'react';
import { Form, Row, Col, Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
    faStar, faHeart,faCapsules, faBagShopping, faClipboard, faHeartPulse, faCalendarDays, faUserDoctor, faTruckMedical, faStethoscope, faXRay, faThermometer, faBell, faCoffee, faHome, faUser, faEnvelope, faCamera,
    faCalendar, faCartPlus, faCheck, faTimes, faArrowUp, faArrowDown,
    faArrowLeft, faArrowRight, faCar, faCloud, faBolt, faUmbrella,
    faBook, faBuilding, faPaperPlane, faSmile, faMusic, faSearch, faThumbsUp,
    faThumbsDown, faEdit, faTrash, faPlus, faMinus, faLightbulb, faMapMarkerAlt,
    faGlobe, faLaptop, faDesktop, faMobileAlt, faTabletAlt, faTools, faCogs, faWrench, faHammer,
    faFileAlt, faFilePdf, faFileWord, faFileExcel, faFolder, faFolderOpen, faArchive, faClipboardList,
    faEnvelopeOpen, faEnvelopeSquare, faPen, faPencilAlt, faRuler, faPaintBrush, faPalette, faCut,
    faPlug, faBatteryFull, faBatteryHalf, faBatteryEmpty, faPhone, faFax, faWifi, faBroadcastTower,
    faTrain, faBus, faPlane, faShip, faAnchor, faRocket, faRoad, faGasPump, faKey, faLock, faLockOpen,
    faShieldAlt, faShieldVirus, faFingerprint, faUniversalAccess, faHearing, faEye, faEyeSlash,
    faGlasses, faMicroscope, faSyringe, faPills, faFirstAid, faBriefcaseMedical, faVirus, faMask,
    faHandshake, faHandHolding, faHandHoldingHeart, faPrayingHands, faHandsHelping, faHandsWash, faHandPeace, faHandRock,
    faHandScissors, faHandSpock, faHandLizard, faHandMiddleFinger, faHandshakeAlt, faFistRaised, faUsers, faUserFriends,
    faUserShield, faUserTie, faUserSecret, faUserNurse, faUserCheck, faUserClock, faUserMinus, faUserPlus,
    faUserSlash, faUserCog, faUsersCog, faChild, faBaby, faMale, faFemale, faGenderless, faTransgender,
    faChess, faChessPawn, faChessQueen, faChessKing, faChessKnight, faChessRook, faChessBishop, faDice,
    faDiceD20, faDiceD6, faDiceFive, faDiceFour, faDiceOne, faDiceSix, faDiceThree, faDiceTwo,
    faGamepad, faHeadphones, faHeadset, faCompactDisc, faMusicNote, faGuitar, faDrum, faDrumstickBite,
    faFilm, faCameraRetro, faVideo, faTv, faProjector, faTicketAlt, faTheaterMasks, faMagic,
    faHatWizard, faBroom, faRing, faGem, faGift, faShoppingCart, faShoppingBasket, faStore, faStoreAlt,
    faTruck, faTruckLoading, faShippingFast, faBox, faBoxOpen, faCube, faCubes, faDiceAlt, faBalanceScale,
    faCoins, faCashRegister, faCreditCard, faWallet, faReceipt, faFileInvoice, faFileInvoiceDollar,
    faPiggyBank, faMoneyBill, faMoneyCheck, faLandmark, faUniversity, faBuildingColumns, faGavel, faScales,
    faBriefcase, faSuitcase, faToolbox, faTrashRestore, faRecycle, faLeaf, faSeedling, faTree, faSpa,faKitMedical,faCalendarPlus
} from '@fortawesome/free-solid-svg-icons';

import {
    faFacebook, faTwitter, faInstagram, faLinkedin, faYoutube, faTiktok, faPinterest, faReddit,
    faSnapchat, faGithub, faGitlab, faBitbucket, faDropbox, faSlack, faMicrosoft, faGoogleDrive,
    faAws, faSpotify, faApple, faAndroid, faWindows, faDribbble, faBehance, faFigma, faAdobe,
    faChrome, faFirefox, faEdge, faOpera, faSafari, faVimeo, faStackOverflow, faStackExchange,
    faWordpress, faJoomla, faDrupal, faMagento, faShopify, faMailchimp, faBuffer, faTrello, faDiscord
} from '@fortawesome/free-brands-svg-icons';


const availableIcons = [
    // Solid Icons
    { name: 'Star', icon: faStar },
    { name: 'Heart', icon: faHeart },
    { name: 'Bag', icon: faBagShopping },
    { name: 'Capsules', icon: faCapsules },
    { name: 'CalendarPlus', icon: faCalendarPlus },
    { name: 'MedicalKit', icon: faKitMedical },
    { name: 'Clipboard', icon: faClipboard },
    { name: 'Heart Pulse', icon: faHeartPulse },
    { name: 'Calendar Days', icon: faCalendarDays },
    { name: 'User Doctor', icon: faUserDoctor },
    { name: 'Truck Medical', icon: faTruckMedical },
    { name: 'Stethoscope', icon: faStethoscope },
    { name: 'X-Ray', icon: faXRay },
    { name: 'Thermometer', icon: faThermometer },
    { name: 'Bell', icon: faBell },
    { name: 'Coffee', icon: faCoffee },
    { name: 'Home', icon: faHome },
    { name: 'User', icon: faUser },
    { name: 'Envelope', icon: faEnvelope },
    { name: 'Camera', icon: faCamera },
    { name: 'Calendar', icon: faCalendar },
    { name: 'Cart Plus', icon: faCartPlus },
    { name: 'Check', icon: faCheck },
    { name: 'Times', icon: faTimes },
    { name: 'Arrow Up', icon: faArrowUp },
    { name: 'Arrow Down', icon: faArrowDown },
    { name: 'Arrow Left', icon: faArrowLeft },
    { name: 'Arrow Right', icon: faArrowRight },
    { name: 'Car', icon: faCar },
    { name: 'Cloud', icon: faCloud },
    { name: 'Bolt', icon: faBolt },
    { name: 'Umbrella', icon: faUmbrella },
    { name: 'Book', icon: faBook },
    { name: 'Building', icon: faBuilding },
    { name: 'Paper Plane', icon: faPaperPlane },
    { name: 'Smile', icon: faSmile },
    { name: 'Music', icon: faMusic },
    { name: 'Search', icon: faSearch },
    { name: 'Thumbs Up', icon: faThumbsUp },
    { name: 'Thumbs Down', icon: faThumbsDown },
    { name: 'Edit', icon: faEdit },
    { name: 'Trash', icon: faTrash },
    { name: 'Plus', icon: faPlus },
    { name: 'Minus', icon: faMinus },
    { name: 'Lightbulb', icon: faLightbulb },
    { name: 'Map Marker', icon: faMapMarkerAlt },
    { name: 'Globe', icon: faGlobe },
    { name: 'Laptop', icon: faLaptop },
    { name: 'Desktop', icon: faDesktop },
    { name: 'Mobile', icon: faMobileAlt },
    { name: 'Tablet', icon: faTabletAlt },
    { name: 'Tools', icon: faTools },
    { name: 'Cogs', icon: faCogs },
    { name: 'Wrench', icon: faWrench },
    { name: 'Hammer', icon: faHammer },
    { name: 'File', icon: faFileAlt },
    { name: 'File PDF', icon: faFilePdf },
    { name: 'File Word', icon: faFileWord },
    { name: 'File Excel', icon: faFileExcel },
    { name: 'Folder', icon: faFolder },
    { name: 'Folder Open', icon: faFolderOpen },
    { name: 'Archive', icon: faArchive },
    { name: 'Clipboard List', icon: faClipboardList },
    { name: 'Envelope Open', icon: faEnvelopeOpen },
    { name: 'Envelope Square', icon: faEnvelopeSquare },
    { name: 'Pen', icon: faPen },
    { name: 'Pencil', icon: faPencilAlt },
    { name: 'Ruler', icon: faRuler },
    { name: 'Paint Brush', icon: faPaintBrush },
    { name: 'Palette', icon: faPalette },
    { name: 'Cut', icon: faCut },
    { name: 'Plug', icon: faPlug },
    { name: 'Battery Full', icon: faBatteryFull },
    { name: 'Battery Half', icon: faBatteryHalf },
    { name: 'Battery Empty', icon: faBatteryEmpty },
    { name: 'Phone', icon: faPhone },
    { name: 'Fax', icon: faFax },
    { name: 'Wifi', icon: faWifi },
    { name: 'Broadcast Tower', icon: faBroadcastTower },
    { name: 'Train', icon: faTrain },
    { name: 'Bus', icon: faBus },
    { name: 'Plane', icon: faPlane },
    { name: 'Ship', icon: faShip },
    { name: 'Anchor', icon: faAnchor },
    { name: 'Rocket', icon: faRocket },
    { name: 'Road', icon: faRoad },
    { name: 'Gas Pump', icon: faGasPump },
    { name: 'Key', icon: faKey },
    { name: 'Lock', icon: faLock },
    { name: 'Lock Open', icon: faLockOpen },
    { name: 'Shield', icon: faShieldAlt },
    { name: 'Fingerprint', icon: faFingerprint },
    { name: 'Universal Access', icon: faUniversalAccess },
    { name: 'Eye', icon: faEye },
    { name: 'Eye Slash', icon: faEyeSlash },
    { name: 'Glasses', icon: faGlasses },
    { name: 'Microscope', icon: faMicroscope },
    { name: 'Syringe', icon: faSyringe },
    { name: 'Pills', icon: faPills },
    { name: 'First Aid', icon: faFirstAid },
    { name: 'Briefcase Medical', icon: faBriefcaseMedical },
    { name: 'Virus', icon: faVirus },
    { name: 'Mask', icon: faMask },
    { name: 'Handshake', icon: faHandshake },
    { name: 'Hands Helping', icon: faHandsHelping },
    { name: 'Child', icon: faChild },
    { name: 'Male', icon: faMale },
    { name: 'Female', icon: faFemale },
    { name: 'Chess', icon: faChess },
    { name: 'Gamepad', icon: faGamepad },
    { name: 'Headphones', icon: faHeadphones },
    { name: 'Film', icon: faFilm },
    { name: 'Video', icon: faVideo },
    { name: 'Tv', icon: faTv },
    { name: 'Ticket', icon: faTicketAlt },
    { name: 'Gift', icon: faGift },
    { name: 'Shopping Cart', icon: faShoppingCart },
    { name: 'Wallet', icon: faWallet },
    { name: 'Piggy Bank', icon: faPiggyBank },
    { name: 'Landmark', icon: faLandmark },
    { name: 'Gavel', icon: faGavel },
    { name: 'Recycle', icon: faRecycle },
    { name: 'Leaf', icon: faLeaf },
    { name: 'Tree', icon: faTree },
    { name: 'Spa', icon: faSpa },
    // Brand Icons
    { name: 'Facebook', icon: faFacebook },
    { name: 'Twitter', icon: faTwitter },
    { name: 'Instagram', icon: faInstagram },
    { name: 'LinkedIn', icon: faLinkedin },
    { name: 'YouTube', icon: faYoutube },
    { name: 'TikTok', icon: faTiktok },
    { name: 'Pinterest', icon: faPinterest },
    { name: 'Reddit', icon: faReddit },
    { name: 'Snapchat', icon: faSnapchat },
    { name: 'GitHub', icon: faGithub },
    { name: 'Microsoft', icon: faMicrosoft },
    { name: 'Spotify', icon: faSpotify },
    { name: 'Apple', icon: faApple },
    { name: 'Android', icon: faAndroid },
    { name: 'Google Drive', icon: faGoogleDrive },
];
const IconCardForm = ({ formValues, setFormValues }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [showModal, setShowModal] = useState(false);

    const handleIconChange = (icon) => {
        setFormValues({ ...formValues, icon });
        setShowModal(false); // Close modal after selection
    };

    const filteredIcons = availableIcons.filter((icon) =>
        icon.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="icon-card-form">
            {/* Selected Icon */}
            <Form.Group controlId="formSelectedIcon" className="mb-3">
                <Form.Label>Selected Icon</Form.Label>
                <div>
                    {formValues.icon ? (
                        <Button
                            variant="outline-secondary"
                            className="d-flex align-items-center"
                            onClick={() => setShowModal(true)}
                        >
                            <FontAwesomeIcon icon={formValues.icon} size="2x" className="me-2" />
                            Change Icon
                        </Button>
                    ) : (
                        <Button variant="outline-secondary" onClick={() => setShowModal(true)}>
                            Choose Icon
                        </Button>
                    )}
                </div>
            </Form.Group>

            {/* Icon Selection Modal */}
            <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Select an Icon</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* Icon Search */}
                    <Form.Group controlId="formIconSearch" className="mb-3">
                        <Form.Control
                            type="text"
                            placeholder="Search icons"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </Form.Group>

                    {/* Icon Selector */}
                    <Row>
                        {filteredIcons.map(({ name, icon }) => (
                            <Col
                                key={name}
                                xs={2} // 10 icons per row
                                className="d-flex justify-content-center align-items-center mb-3"
                            >
                                <Button
                                    variant={formValues.icon === icon ? 'primary' : 'outline-secondary'}
                                    onClick={() => handleIconChange(icon)}
                                    className="w-100"
                                >
                                    <FontAwesomeIcon icon={icon} size="2x" />
                                </Button>
                            </Col>
                        ))}
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Title Input */}
            <Form.Group controlId="formCardTitle" className="mb-3">
                <Form.Label>Title</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter card title"
                    value={formValues.title || ''}
                    onChange={(e) => setFormValues({ ...formValues, title: e.target.value })}
                />
            </Form.Group>

            {/* Description Input */}
            <Form.Group controlId="formCardDescription" className="mb-3">
                <Form.Label>Description</Form.Label>
                <Form.Control
                    as="textarea"
                    placeholder="Enter description"
                    value={formValues.description || ''}
                    onChange={(e) => setFormValues({ ...formValues, description: e.target.value })}
                    rows={3}
                />
            </Form.Group>
        </div>
    );
};

export default IconCardForm;
