import React from 'react';
import { Form, Button } from 'react-bootstrap';

const SingleColumnContactForm = ({ formFields, styles, onSubmit }) => {
    const handleSubmit = (e) => {
        e.preventDefault();
        const data = {};
        formFields.forEach((field) => {
            data[field.name] = e.target[field.name].value;
        });
        if (onSubmit) onSubmit(data);
    };

    return React.createElement(
        Form,
        { onSubmit: handleSubmit, style: styles.container },
        formFields.map((field, index) =>
            React.createElement(
                Form.Group,
                { key: index, className: 'mb-3', controlId: `form_${field.name}` },
                React.createElement(Form.Label, { style: styles.labels }, field.label),
                React.createElement(Form.Control, {
                    type: field.type || 'text',
                    name: field.name,
                    placeholder: field.placeholder,
                    required: field.required,
                    style: styles.inputs,
                })
            )
        ),
        React.createElement(
            Button,
            { variant: 'primary', type: 'submit', style: styles.button },
            styles.buttonText || 'Submit'
        )
    );
};

export default SingleColumnContactForm;
