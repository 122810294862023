import React from 'react';
import { useDrag } from 'react-dnd';

const DraggableComponent = ({type, rowID, sectionId, columnIdx, componentIdx, props, children, onCloneSection}) => {
    const [, drag] = useDrag(() => ({
        type: "component",
        item: { type, rowID, sectionId, columnIdx, componentIdx, props },
    }), [type, rowID, sectionId, columnIdx, componentIdx, props]);

    return (
        <div ref={drag} className="drag-wrap">
            {children}
        </div>
    );
};

export default DraggableComponent;
