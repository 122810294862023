import React, { useMemo, useState, useEffect } from 'react';
import { Container, Row, Col, Button, Form, Card, ListGroup, Accordion, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCart } from './component/CartContext'; // Adjust the import path as necessary
import { faTrash, faPlus, faMinus, faArrowRight } from '@fortawesome/free-solid-svg-icons';

import { loadStripe } from '@stripe/stripe-js';
import { API_URL } from '../config';
import Loading from './component/Loading';

const stripePromise = loadStripe('pk_test_51N1rXoBBF79XvB8GY7prfq24ekAcJmMB339TtHEss4OOTx4NJVNlDIdSiJuMtmdbzrUDjVqQKOJMHGxixFv0Uvuy004aDR0ETY'); // Replace with your actual publishable key

const CartPage = () => {
    const { cartItems, updateQuantity, removeFromCart } = useCart();
    const [currentStep, setCurrentStep] = useState(2); // Control which accordion is active
    
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedState, setSelectedState] = useState('');

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [addresses, setAddresses] = useState([]);
    const [selectedAddressId, setSelectedAddressId] = useState(null);
    const [newAddress, setNewAddress] = useState({
        first_name: '',
        last_name: '',
        street_address_1: '',
        street_address_2: '',
        city: '',
        state: '',
        country: '',
        postal_code: '',
        phone: '',
    });
    const [paymentMethod, setPaymentMethod] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (currentStep === 1) {
            fetchAddresses();
        }
    }, [currentStep]);

    const fetchAddresses = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`${API_URL}/addresses`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            const data = await response.json();
            setAddresses(data);
        } catch (error) {
            console.error('Error fetching addresses:', error);
        }
    };

    const handleLogin = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${API_URL}/login`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email, password }),
            });
            const data = await response.json();
            setIsLoading(false);
            if (data.token) {
                localStorage.setItem('token', data.token);
                alert('Login successful');
                setCurrentStep(1);
            } else {
                alert(data.message || 'Login failed');
            }
        } catch (error) {
            setIsLoading(false);
            console.error('Login error:', error);
        }
    };

    const handleRegister = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${API_URL}/register`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email, password, full_name: newAddress.first_name, phone_number: newAddress.phone }),
            });
            const data = await response.json();
            setIsLoading(false);
            if (data.customer) {
                alert('Registration successful');
                setCurrentStep(1);
            } else {
                alert(data.errors || 'Registration failed');
            }
        } catch (error) {
            setIsLoading(false);
            console.error('Registration error:', error);
        }
    };

    const handleAddAddress = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`${API_URL}/addresses`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
                body: JSON.stringify(newAddress),
            });
            const data = await response.json();
            if (data.address) {
                alert('Address added successfully');
                fetchAddresses();
                setNewAddress({
                    first_name: '',
                    last_name: '',
                    street_address_1: '',
                    street_address_2: '',
                    city: '',
                    state: '',
                    country: '',
                    postal_code: '',
                    phone: '',
                });
            } else {
                alert('Error adding address');
            }
        } catch (error) {
            console.error('Error adding address:', error);
        }
    };

    const totals = useMemo(() => {
        let subtotal = cartItems.reduce((total, item) => total + (item.quantity * parseFloat(item.product.basePrice)), 0);
        let discount = 10; // Example discount
        let delivery = 5; // Example delivery charge
        return {
            subtotal: subtotal.toFixed(2),
            discount: discount.toFixed(2),
            delivery: delivery.toFixed(2),
            total: (subtotal - discount + delivery).toFixed(2),
        };
    }, [cartItems]);

    const handlePayment = async () => {
        if (paymentMethod === 'gateway') {
            setLoading(true);
            const stripe = await stripePromise;
            const response = await fetch(`${API_URL}/create-checkout-session`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ cartItems }),
            });
            const session = await response.json();
            const result = await stripe.redirectToCheckout({ sessionId: session.id });
            if (result.error) {
                console.log(result.error.message);
            }
            setLoading(false);
        } else {
            alert('Order placed with Cash on Delivery!');
        }
    };



    const handleIncrement = (product, selectedOptions) => {
        const newQuantity = product.quantity + 1;
        updateQuantity(product.product.product_id, newQuantity, selectedOptions);
    };

    const handleDecrement = (product, selectedOptions) => {
        const newQuantity = Math.max(1, product.quantity - 1);
        updateQuantity(product.product.product_id, newQuantity, selectedOptions);
    };

    const handleRemoveItem = (product, selectedOptions) => {
        removeFromCart(product.product_id, selectedOptions);
    };
        useEffect(() => {
        fetchCountries();
    }, []);

    const fetchCountries = async () => {
        try {
            const response = await fetch(`${API_URL}/get-countries`);
            const data = await response.json();
            setCountries(data);
        } catch (error) {
            console.error('Error fetching countries:', error);
        }
    };

    const handleCountryChange = (e) => {
        const countryId = e.target.value;
        setSelectedCountry(countryId);
        setNewAddress({ ...newAddress, country: countryId });
        setStates([]);
        setCities([]);
        fetchStates(countryId);
    };

    const handleStateChange = (e) => {
        const stateId = e.target.value;
        setSelectedState(stateId);
        setNewAddress({ ...newAddress, state: stateId });
        setCities([]);
        fetchCities(stateId);
    };

    const handleCityChange = (e) => {
        const cityId = e.target.value;
        setNewAddress({ ...newAddress, city: cityId });
    };
  const fetchStates = async (countryId) => {
        try {
            const response = await fetch(`${API_URL}/get-states/${countryId}`);
            const data = await response.json();
            setStates(data);
        } catch (error) {
            console.error('Error fetching states:', error);
        }
    };

    const fetchCities = async (stateId) => {
        try {
            const response = await fetch(`${API_URL}/get-cities/${stateId}`);
            const data = await response.json();
            setCities(data);
        } catch (error) {
            console.error('Error fetching cities:', error);
        }
    };


    return (
        <div className="margin-top cart-page-wrap">
            {loading ? <Loading text="Processing your request..." /> : ''}
            <Container fluid>
                <div className="breadcrumbs desktop-view">
                    <div className="container-fluid">
                        <ul>
                            <li><a href="/home">Home</a></li>
                            <li>Checkout</li>
                        </ul>
                    </div>
                </div>
                <Row>
                    <Col>
                        <Accordion activeKey={`${currentStep}`}>
                            {/* Step 1: Login or Signup */}
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                    1. Login or Signup
                                    {currentStep > 0 && (
                                        <Button
                                            variant="link"
                                            className="ms-auto"
                                            onClick={() => setCurrentStep(0)}
                                        >
                                            Change
                                        </Button>
                                    )}
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Alert variant="info">Login to continue or sign up for an account if you're new!</Alert>
                                    <Form>
                                        <Form.Group controlId="email">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control
                                                type="email"
                                                placeholder="Enter your email"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="password" className="mt-3">
                                            <Form.Label>Password</Form.Label>
                                            <Form.Control
                                                type="password"
                                                placeholder="Enter your password"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                            />
                                        </Form.Group>
                                    </Form>
                                    <Button
                                        variant="primary"
                                        className="mt-3"
                                        onClick={() => setCurrentStep(1)}
                                    >
                                        Next Step
                                    </Button>
                                </Accordion.Body>
                            </Accordion.Item>

                 
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>
                                    2. Delivery Address
                                    {currentStep > 1 && (
                                        <Button
                                            variant="link"
                                            className="ms-auto"
                                            onClick={() => setCurrentStep(1)}
                                        >
                                            Change
                                        </Button>
                                    )}
                                </Accordion.Header>
                                
                                <Accordion.Body>
                                    <Form>
                                        <Form.Group controlId="first_name" className="mt-3">
                                            <Form.Label>First Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter your first name"
                                                value={newAddress.first_name}
                                                onChange={(e) =>
                                                    setNewAddress({ ...newAddress, first_name: e.target.value })
                                                }
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="last_name" className="mt-3">
                                            <Form.Label>Last Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter your last name"
                                                value={newAddress.last_name}
                                                onChange={(e) =>
                                                    setNewAddress({ ...newAddress, last_name: e.target.value })
                                                }
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="country" className="mt-3">
                                            <Form.Label>Country</Form.Label>
                                            <Form.Control
                                                as="select"
                                                value={selectedCountry}
                                                onChange={handleCountryChange}
                                            >
                                                <option value="">Select Country</option>
                                                {countries.map((country) => (
                                                    <option key={country.id} value={country.id}>
                                                        {country.name}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group controlId="state" className="mt-3">
                                            <Form.Label>State</Form.Label>
                                            <Form.Control
                                                as="select"
                                                value={selectedState}
                                                onChange={handleStateChange}
                                                disabled={!states.length}
                                            >
                                                <option value="">Select State</option>
                                                {states.map((state) => (
                                                    <option key={state.id} value={state.id}>
                                                        {state.name}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group controlId="city" className="mt-3">
                                            <Form.Label>City</Form.Label>
                                            <Form.Control
                                                as="select"
                                                value={newAddress.city}
                                                onChange={handleCityChange}
                                                disabled={!cities.length}
                                            >
                                                <option value="">Select City</option>
                                                {cities.map((city) => (
                                                    <option key={city.id} value={city.id}>
                                                        {city.name}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group controlId="street_address_1" className="mt-3">
                                            <Form.Label>Street Address 1</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter street address"
                                                value={newAddress.street_address_1}
                                                onChange={(e) =>
                                                    setNewAddress({ ...newAddress, street_address_1: e.target.value })
                                                }
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="street_address_2" className="mt-3">
                                            <Form.Label>Street Address 2 (Optional)</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter additional address details"
                                                value={newAddress.street_address_2}
                                                onChange={(e) =>
                                                    setNewAddress({ ...newAddress, street_address_2: e.target.value })
                                                }
                                            />
                                        </Form.Group>
                                    </Form>
                                    <Button
                                        variant="primary"
                                        className="mt-3"
                                        onClick={() => setCurrentStep(2)}
                                    >
                                        Next Step
                                    </Button>
                                </Accordion.Body>
                            </Accordion.Item>


                            {/* Step 3: Order Summary */}
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>
                                    3. Order Summary
                                    {currentStep > 2 && (
                                        <Button
                                            variant="link"
                                            className="ms-auto"
                                            onClick={() => setCurrentStep(2)}
                                        >
                                            Change
                                        </Button>
                                    )}
                                </Accordion.Header>
                                <Accordion.Body>
                <Row>
                    <Col md={8} className="cart-left-wrap mb-4">
                        <div className="address-wrap"><span>Deliver to:</span> SCO 22-23, Office No 312, 3rd floor, K10 Tower, Feroze Gandhi Market, Ludhiana</div>
                        <Card>
                            <Card.Body className="p-0">
                                {cartItems.map((item, index) => (
                                    <div className="cart-items">
                                        <div className="product-img">
                                            <img src="https://www.fiklu.com/media/4" />
                                        </div>
                                        <div className="cart-product-detail">
                                            <div className="product-name">{item.product.product_name}</div>
                                            <div className="product-price">Price: ${item.product.basePrice}</div>
                                            <div className="quantity-controls">
                                                <div className="quantity-inner">
                                                    <Button variant="outline-secondary" onClick={() => handleDecrement(item, item.selectedOptions)}>
                                                        -
                                                    </Button>

                                                    <Form.Control
                                                        type="text"
                                                        readOnly
                                                        value={item.quantity}
                                                    />
                                                    <Button variant="outline-secondary" onClick={() => handleIncrement(item, item.selectedOptions)}>
                                                        +
                                                    </Button>    
                                                </div>
                                                <Button variant="" className="remove-btn" onClick={() => handleRemoveItem(item.product, item.selectedOptions)} style={{ marginLeft: 'auto' }}>
                                                    <FontAwesomeIcon icon={faTrash} /> Remove from cart
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Card.Body>
                        </Card>
                        <div className="proceed-action text-end">
                            <Button variant="success"       onClick={() => setCurrentStep(3)} disabled={loading}>
                                {loading ? 'Processing...' : 'Proceed to Pay'}  <FontAwesomeIcon icon={faArrowRight} />
                            </Button>
                        </div>
                        
                    </Col>
                    <Col md={4}>
                        <div className="cart-summary-wrap">
                            <h4>Order Summary</h4>
                            <ListGroup variant="flush">
                                <ListGroup.Item>Subtotal <span>${totals.subtotal}</span></ListGroup.Item>
                                <ListGroup.Item>Discount  <span>-${totals.discount}</span></ListGroup.Item>
                                <ListGroup.Item>Delivery  <span>${totals.delivery}</span></ListGroup.Item>
                                <ListGroup.Item className="total-wrap"><strong>Total  <span>${totals.total}</span></strong></ListGroup.Item>
                            </ListGroup>
                        </div>                        
                    </Col>
                </Row>
                                   
      
                                </Accordion.Body>
                            </Accordion.Item>

                            {/* Step 4: Payment Options */}
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>4. Payment Options</Accordion.Header>
                                <Accordion.Body>
                                    <Form>
                                        <Form.Check
                                            type="radio"
                                            id="cod"
                                            label="Cash on Delivery"
                                            name="paymentMethod"
                                            value="cod"
                                            checked={paymentMethod === 'cod'}
                                            onChange={(e) => setPaymentMethod(e.target.value)}
                                        />
                                        <Form.Check
                                            type="radio"
                                            id="gateway"
                                            label="Payment Gateway"
                                            name="paymentMethod"
                                            value="gateway"
                                            checked={paymentMethod === 'gateway'}
                                            onChange={(e) => setPaymentMethod(e.target.value)}
                                        />
                                    </Form>
                                    <Button
                                        variant="success"
                                        className="mt-3"
                                        onClick={handlePayment}
                                        disabled={!paymentMethod}
                                    >
                                        Place Order
                                    </Button>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default CartPage;
