import React from 'react';

function DividerComponent({ styles = {} }) {
    // Extract styles nested under `divider`
    const dividerStyles = styles.divider || {};

    return <hr style={dividerStyles} />;
}

export default DividerComponent;
