import React from 'react';
import GridTemplate from './templates/gallery/GridTemplate';
import MasonryTemplate from './templates/gallery/MasonryTemplate';
import CarouselTemplate from './templates/gallery/CarouselTemplate';
import OverlayTemplate from './templates/gallery/OverlayTemplate';
import PolaroidTemplate from './templates/gallery/PolaroidTemplate';
import LightboxTemplate from './templates/gallery/LightboxTemplate';

const GalleryComponent = ({ images, template = "Grid", styles = {} }) => {
    switch (template) {
        case "Masonry":
            return <MasonryTemplate images={images} styles={styles} />;
        case "Carousel":
            return <CarouselTemplate images={images} styles={styles} />;
        case "Overlay":
            return <OverlayTemplate images={images} styles={styles} />;
        case "Polaroid":
            return <PolaroidTemplate images={images} />;
        case "Lightbox":
            return <LightboxTemplate images={images} />;
        default: // Grid
            return <GridTemplate images={images} styles={styles} />;
    }
};

export default GalleryComponent;
