import React from 'react';
import DefaultBreadcrumb from './templates/breadcrumb/DefaultBreadcrumb';
import ModernBreadcrumb from './templates/breadcrumb/ModernBreadcrumb';
import CustomBreadcrumb from './templates/breadcrumb/CustomBreadcrumb';

const Breadcrumb = ({ items, separator = '/', template = 'default', styles = {} }) => {
  switch (template) {
    case 'Modern':
      return <ModernBreadcrumb items={items} separator={separator} styles={styles} />;
    case 'Custom':
      return <CustomBreadcrumb items={items} separator={separator} styles={styles} />;
    default:
      return <DefaultBreadcrumb items={items} separator={separator} styles={styles} />;
  }
};

export default Breadcrumb;
