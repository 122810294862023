import React from 'react';
import './GridTemplate.css';

const GridTemplate = ({ images, styles }) => (
    <div className="gallery-grid">
        {images.map((img, idx) => (
            <div
                key={idx}
                className="gallery-item grid"
                style={{
                    ...styles.image,
                    backgroundImage: `url(${img.src})`,
                }}
            />
        ))}
    </div>
);

export default GridTemplate;
