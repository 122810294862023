import React from 'react';
import { useAppData } from './AppDataContext';
import { LinearProgress, Box, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// Create a custom theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#0056b3',
    },
    background: {
      default: '#000',
    },
    text: {
      primary: '#fff',
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    h1: {
      fontWeight: 600,
      fontSize: '2rem',
      color: '#fff',
    },
    subtitle1: {
      fontWeight: 400,
      fontSize: '1.2rem',
      color: '#ccc',
    },
  },
});

function SplashScreen() {
  // Access subdomain from AppDataContext
  const { subdomain } = useAppData();

  return (
    <ThemeProvider theme={theme}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100vh"
        sx={{
          backgroundColor: theme.palette.background.default,
        }}
      >
        {/* Display the Subdomain */}
        <Typography variant="h1" sx={{ mb: 2 }}>
          Welcome to {subdomain.charAt(0).toUpperCase() + subdomain.slice(1)}!
        </Typography>
        <Typography variant="subtitle1" sx={{ mb: 4 }}>
          Preparing your personalized experience...
        </Typography>

        {/* Loading Progress */}
        <Box width="80%" maxWidth="500px">
          <LinearProgress color="primary" />
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default SplashScreen;
