import React, { useState } from 'react';
import { Form, Button, Image } from 'react-bootstrap';
import MediaLibrary from '../MediaLibrary';

const HorizontalCardForm = ({ formValues, setFormValues }) => {
    const [showLibrary, setShowLibrary] = useState(false);

    const handleImageSelect = (url) => {
        setFormValues({ ...formValues, imageUrl: url });
        setShowLibrary(false); // Close the MediaLibrary after selection
    };

    const handleRemoveImage = () => {
        setFormValues({ ...formValues, imageUrl: '' });
    };

    return (
        <>
            <div className="carousel-wrap">
                <div className="row">
                    <div className="col-md-3">
                        <div className="media-img-wrap">
                            {formValues.imageUrl ? (
                                <Image
                                    src={formValues.imageUrl}
                                    alt="Selected Image"
                                    thumbnail
                                    style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                                />
                            ) : (
                                <div
                                    style={{
                                        width: '100px',
                                        height: '100px',
                                        backgroundColor: '#f0f0f0',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        border: '1px solid #ddd',
                                        borderRadius: '5px',
                                    }}
                                >
                                    No Image Selected
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-md-9 single-img-wrap-e">
                        <MediaLibrary onSelectImage={handleImageSelect} />

                        {formValues.imageUrl && (
                            <Button variant="danger" className="mt-2" onClick={handleRemoveImage}>
                                Remove Image
                            </Button>
                        )}
                    </div>
                </div>
            </div>
                


            {/* Card Title */}
            <Form.Group controlId="formCardTitle" className="mb-3">
                <Form.Label>Card Title</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter card title"
                    value={formValues.title || ''}
                    onChange={(e) => setFormValues({ ...formValues, title: e.target.value })}
                />
            </Form.Group>



            {/* Card Body Text */}
            <Form.Group controlId="formCardBodyText" className="mb-3">
                <Form.Label>Body Text</Form.Label>
                <Form.Control
                    as="textarea"
                    placeholder="Enter body text"
                    value={formValues.bodyText || ''}
                    onChange={(e) => setFormValues({ ...formValues, bodyText: e.target.value })}
                    rows={3}
                />
            </Form.Group>
        </>
    );
};

export default HorizontalCardForm;
