import React, { createContext, useState, useEffect, useContext } from 'react';
import { API_URL } from '../../config';

const AppDataContext = createContext();

export const useAppData = () => useContext(AppDataContext);

export const AppDataProvider = ({ children }) => {
    const [menus, setMenus] = useState([]);
    const [logoUrl, setLogoUrl] = useState('');
    const [isDataLoading, setIsDataLoading] = useState(true);
    const [carouselData, setCarouselData] = useState([]);
    const [productListingTitle, setProductListingTitle] = useState([]);
    const [categories, setCategories] = useState([]);
    const [products, setProducts] = useState([]);
    const [headerBackgroundColor, setHeaderBackgroundColor] = useState('');
    const [headerMenuFontColor, setHeaderMenuFontColor] = useState('');

    const subdomain = window.location.hostname.split('.')[0];  // Simplified for demonstration

 //   const subdomain = 'astroyami';

    useEffect(() => {
        const fetchData = async () => {
            setIsDataLoading(true);
            try {
                const [menuResponse, logoResponse] = await Promise.all([
                    fetch(`${API_URL}/menus?account_key=${subdomain}`),
                    fetch(`${API_URL}/meta-data?account_key=${subdomain}`, {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ identifiers: ['website_logo', 'header_background_color', 'header_menu_font_color'] }),
                    })
                ]);

                const menuData = await menuResponse.json();
                const metaData = await logoResponse.json();

                if (menuData) {
                    setMenus(Object.values(menuData)); // Assuming data structure needs this conversion
                }

                metaData.forEach(item => {
                    if (item.identifier === 'website_logo') {
                        setLogoUrl(item.data);
                    } else if (item.identifier === 'header_background_color') {
                        setHeaderBackgroundColor(item.data);
                    } else if (item.identifier === 'header_menu_font_color') {
                        setHeaderMenuFontColor(item.data);
                    }
                });

                setIsDataLoading(false);
            } catch (error) {
                console.error('Failed to fetch app data:', error);
                setIsDataLoading(false);
            }
        };

        fetchData();
    }, [subdomain]);

    return (
        <AppDataContext.Provider
            value={{
                menus,
                logoUrl,
                isDataLoading,
                subdomain,
                carouselData,
                productListingTitle,
                categories,
                products,
                headerBackgroundColor,
                headerMenuFontColor,
            }}
        >
            {children}
        </AppDataContext.Provider>
    );
};
