import React from 'react';
import { Form, Button, Image } from 'react-bootstrap';
import MediaLibrary from '../MediaLibrary';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

const TestimonialForm = ({ formValues, setFormValues, handleAddJsonArrayItem, handleRemoveJsonArrayItem }) => {
    const renderJsonArrayFields = (fieldName, itemSchema) => (
        <>
            {formValues[fieldName]?.map((testimonial, index) => (
                <div key={index} className="carousel-wrap testimonial-wrap mb-3">
                    <div className="row">
                        {/* Media Library for Image */}
                        <div className="col-md-3">
                            <div className="media-img-wrap">
                                {testimonial.image ? (
                                    <Image
                                        src={testimonial.image}
                                        alt={testimonial.name || 'Testimonial'}
                                        thumbnail
                                        style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                                    />
                                ) : (
                                    <div
                                        style={{
                                            width: '100px',
                                            height: '100px',
                                            backgroundColor: '#ddd',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            margin: 'auto',
                                        }}
                                    >
                                        No Image
                                    </div>
                                )}

                                <MediaLibrary
                                    onSelectImage={(url) => {
                                        const updatedTestimonials = [...formValues[fieldName]];
                                        updatedTestimonials[index].image = url;
                                        setFormValues({ ...formValues, [fieldName]: updatedTestimonials });
                                    }}
                                />
                            </div>
                        </div>

                        {/* Name, Position, and Message */}
                        <div className="col-md-9">
                            <div className="mb-3">
                                <label className="form-label">Testimony name</label>
                                <Form.Control
                                    type="text"
                                    placeholder="Name"
                                    value={testimonial.name || ''}
                                    onChange={(e) => {
                                        const updatedTestimonials = [...formValues[fieldName]];
                                        updatedTestimonials[index].name = e.target.value;
                                        setFormValues({ ...formValues, [fieldName]: updatedTestimonials });
                                    }}
                                    className="mb-2"
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Testimony position</label>
                                <Form.Control
                                    type="text"
                                    placeholder="Position"
                                    value={testimonial.position || ''}
                                    onChange={(e) => {
                                        const updatedTestimonials = [...formValues[fieldName]];
                                        updatedTestimonials[index].position = e.target.value;
                                        setFormValues({ ...formValues, [fieldName]: updatedTestimonials });
                                    }}
                                    className="mb-2"
                                />
                            </div>

                            <div className="mb-3">
                                <label className="form-label">Testimony quote</label>
                                <Form.Control
                                    as="textarea"
                                    placeholder="Message"
                                    value={testimonial.message || ''}
                                    onChange={(e) => {
                                        const updatedTestimonials = [...formValues[fieldName]];
                                        updatedTestimonials[index].message = e.target.value;
                                        setFormValues({ ...formValues, [fieldName]: updatedTestimonials });
                                    }}
                                    rows={3}
                                    className="mb-2"
                                />
                            </div>
                        </div>
                    </div>
                    <Button
                        variant="danger"
                        className="mt-2"
                        onClick={() => handleRemoveJsonArrayItem(fieldName, index)}
                    >
                        Remove Testimonial
                    </Button>
                </div>
            ))}
            <Button
                variant="primary"
                className="add-more-media"
                onClick={() =>
                    handleAddJsonArrayItem(fieldName, {
                        image: '',
                        name: '',
                        position: '',
                        message: '',
                    })
                }
            >
                <FontAwesomeIcon icon={faPlus} /> Add Testimonial
            </Button>
        </>
    );

    return (
        <div>
            

            {/* Testimonials */}
            {renderJsonArrayFields('testimonials', {
                image: '',
                name: '',
                position: '',
                message: '',
            })}
        </div>
    );
};

export default TestimonialForm;
