import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import './TabsTemplates.css'; // Import CSS file for templates

function TabsComponent({ tabs, template = "default", styles = {} }) {
    const containerClassName = `${template.toLowerCase()}-tabs`; // Dynamic class based on template

    const containerStyles = styles?.tabContainer || {};
    const tabStyles = styles?.tabItem || {};
    const activeTabStyles = styles?.tabItemActive || {};
    const tabContentStyles = styles?.tabContent || {};

    return (
        <div className={containerClassName} style={containerStyles}>
            <Tabs 
                defaultActiveKey={tabs?.[0]?.key || "default"} // Default to first tab or fallback
                id="styled-tabs"
                className="custom-tabs"
            >
                {tabs && tabs.length > 0
                    ? tabs.map((tab, index) => (
                        <Tab
                            eventKey={tab.key || `tab-${index}`} // Ensure unique eventKey for each Tab
                            title={
                                <span 
                                    style={tab.key === tabs?.[0]?.key ? activeTabStyles : tabStyles}
                                >
                                    {tab.title}
                                </span>
                            }
                            key={tab.key || `tab-${index}`} // Unique key for React rendering
                        >
                            <div style={tabContentStyles}>
                                {tab.content}
                            </div>
                        </Tab>
                    ))
                    : (
                        <Tab eventKey="default" title="Default Tab">
                            <div style={tabContentStyles}>
                                Default Tab Content
                            </div>
                        </Tab>
                    )}
            </Tabs>
        </div>
    );
}

export default TabsComponent;
