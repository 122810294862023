import React from 'react';
import { Form, Button, Image } from 'react-bootstrap';
import MediaLibrary from '../MediaLibrary';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

const FeatureCardsForm = ({ formValues, setFormValues, handleAddJsonArrayItem, handleRemoveJsonArrayItem }) => {
    const renderJsonArrayFields = (fieldName, itemSchema) => (
        <>
            {formValues[fieldName]?.map((feature, index) => (
                <div key={index} className="carousel-wrap">
                    <div className="row">
                        {/* Media Library for Image */}
                        <div className="col-md-3">
                            <div className="media-img-wrap">
                                {feature.image ? (
                                    <Image
                                        src={feature.image}
                                        alt={feature.title || 'Feature Image'}
                                        thumbnail
                                        style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                                    />
                                ) : (
                                    <div
                                        style={{
                                            width: '100px',
                                            height: '100px',
                                            backgroundColor: '#ddd',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            margin: 'auto',
                                        }}
                                    >
                                        No Image
                                    </div>
                                )}

                                <MediaLibrary
                                    onSelectImage={(url) => {
                                        const updatedFeatures = [...formValues[fieldName]];
                                        updatedFeatures[index].image = url; // Update image field
                                        setFormValues({ ...formValues, [fieldName]: updatedFeatures });
                                    }}
                                />
                            </div>
                        </div>

                        {/* Title and Description */}
                        <div className="col-md-9">
                            <div className="mb-3">
                                <label className="form-label">Card Title</label>
                                <Form.Control
                                    type="text"
                                    placeholder="Title"
                                    value={feature.title || ''}
                                    onChange={(e) => {
                                        const updatedFeatures = [...formValues[fieldName]];
                                        updatedFeatures[index].title = e.target.value;
                                        setFormValues({ ...formValues, [fieldName]: updatedFeatures });
                                    }}
                                    className="mb-2"
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Card description</label>
                                <Form.Control
                                    as="textarea"
                                    placeholder="Description"
                                    value={feature.description || ''}
                                    onChange={(e) => {
                                        const updatedFeatures = [...formValues[fieldName]];
                                        updatedFeatures[index].description = e.target.value;
                                        setFormValues({ ...formValues, [fieldName]: updatedFeatures });
                                    }}
                                    rows={3}
                                    className="mb-2"
                                />
                            </div>
                        </div>
                    </div>
                    <Button
                        variant="danger"
                        className="mt-2"
                        onClick={() => handleRemoveJsonArrayItem(fieldName, index)}
                    >
                        Remove Feature
                    </Button>
                </div>
            ))}
            <Button
                variant="primary"
                className="add-more-media"
                onClick={() => handleAddJsonArrayItem(fieldName, { image: '', title: '', description: '' })}
            >
                <FontAwesomeIcon icon={faPlus} /> Add Feature
            </Button>
        </>
    );

    return (
        <div>
            {/* Headline */}
            <label className="form-label">Features card head</label>
            <Form.Control
                type="text"
                placeholder="Headline (e.g., Our Features)"
                value={formValues.headline || ''}
                onChange={(e) => setFormValues({ ...formValues, headline: e.target.value })}
                className="mb-3"
            />

            {/* Description */}
            <label className="form-label">Features card description</label>
            <Form.Control
                as="textarea"
                placeholder="Section Description (e.g., Discover what we offer)"
                value={formValues.description || ''}
                onChange={(e) => setFormValues({ ...formValues, description: e.target.value })}
                rows={3}
                className="mb-3"
            />

            <hr />

            {/* Feature Cards */}
            {renderJsonArrayFields('features', { image: '', title: '', description: '' })}
        </div>
    );
};

export default FeatureCardsForm;
