import React from 'react';

import './SocialMediaIconsComponent.css'; // CSS file for templates

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';

const SocialMediaIconsComponent = ({ links, styles = {}, template = "Default"  }) => {
    switch (template) {
        case "Modern":
            return (
                <div className="modern-social" style={styles.container}>
                    {links.map((link, index) => {
                        let icon;

                        // Determine the icon based on the name
                        switch (link.name) {
                            case 'facebook':
                                icon = faFacebook;
                                break;
                            case 'twitter':
                                icon = faTwitter;
                                break;
                            case 'instagram':
                                icon = faInstagram;
                                break;
                            case 'linkedin':
                                icon = faLinkedin;
                                break;
                            default:
                                return null;
                        }

                        return (
                            <a
                                key={index}
                                href={link.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="icon-link"
                                style={styles.icon}
                            >
                                <FontAwesomeIcon icon={icon} />
                            </a>
                        );
                    })}
                </div>
            );

        case "Minimal":
            return (
                <div className="minimal-social" style={styles.container}>
                    {links.map((link, index) => {
                        let icon;

                        // Determine the icon based on the name
                        switch (link.name) {
                            case 'facebook':
                                icon = faFacebook;
                                break;
                            case 'twitter':
                                icon = faTwitter;
                                break;
                            case 'instagram':
                                icon = faInstagram;
                                break;
                            case 'linkedin':
                                icon = faLinkedin;
                                break;
                            default:
                                return null;
                        }

                        return (
                            <a
                                key={index}
                                href={link.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="icon-link"
                                style={styles.icon}
                            >
                                <FontAwesomeIcon icon={icon} />
                            </a>
                        );
                    })}
                </div>

            );

        case "Custom":
            return (

                <div className="custom-social" style={styles.container}>
                    {links.map((link, index) => {
                        let icon;

                        // Determine the icon based on the name
                        switch (link.name) {
                            case 'facebook':
                                icon = faFacebook;
                                break;
                            case 'twitter':
                                icon = faTwitter;
                                break;
                            case 'instagram':
                                icon = faInstagram;
                                break;
                            case 'linkedin':
                                icon = faLinkedin;
                                break;
                            default:
                                return null;
                        }

                        return (
                            <a
                                key={index}
                                href={link.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="icon-link"
                                style={styles.icon}
                            >
                                <FontAwesomeIcon icon={icon} />
                            </a>
                        );
                    })}
                </div>
            );

        default:
            return (
                <div className="default-social" style={styles.container}>
                    {links.map((link, index) => {
                        let icon;

                        // Determine the icon based on the name
                        switch (link.name) {
                            case 'facebook':
                                icon = faFacebook;
                                break;
                            case 'twitter':
                                icon = faTwitter;
                                break;
                            case 'instagram':
                                icon = faInstagram;
                                break;
                            case 'linkedin':
                                icon = faLinkedin;
                                break;
                            default:
                                return null;
                        }

                        return (
                            <a
                                key={index}
                                href={link.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="icon-link"
                                style={styles.icon}
                            >
                                <FontAwesomeIcon icon={icon} />
                            </a>
                        );
                    })}
                </div>
            );
        
}

};

export default SocialMediaIconsComponent;
