import React from 'react';
import './OverlayTemplate.css';

const OverlayTemplate = ({ images, styles }) => (
    <div className="gallery-overlay">
        {images.map((img, idx) => (
            <div
                key={idx}
                className="gallery-item overlay"
                style={{
                    ...styles.image,
                    backgroundImage: `url(${img.src})`,
                }}
            >
                <div className="overlay">
                    <h4>{img.alt || `Image ${idx + 1}`}</h4>
                </div>
            </div>
        ))}
    </div>
);

export default OverlayTemplate;
