import React from 'react';
import ClassicTeamTemplate from './templates/teamsection/ClassicTeamTemplate';
import ModernTeamTemplate from './templates/teamsection/ModernTeamTemplate';
import SplitTeamTemplate from './templates/teamsection/SplitTeamTemplate';
import CardOverlayTeamTemplate from './templates/teamsection/CardOverlayTeamTemplate';
import MinimalGridTeamTemplate from './templates/teamsection/MinimalGridTeamTemplate';
import './TeamSectionComponent.css';


const TeamSectionComponent = ({ template, ...props }) => {
    switch (template) {
        case "Modern":
            return <ModernTeamTemplate {...props} />;
        case "Split":
            return <SplitTeamTemplate {...props} />;
        case "CardOverlay":
            return <CardOverlayTeamTemplate {...props} />;
        case "MinimalGrid":
            return <MinimalGridTeamTemplate {...props} />;
        default: // Classic
            return <ClassicTeamTemplate {...props} />;
    }
};

export default TeamSectionComponent;
