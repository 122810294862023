// ModernCarousel.jsx

import React from 'react';
import './ModernCarousel.css';
import { Carousel, Button } from 'react-bootstrap';

function ModernCarousel({ items, styles = {} }) {
    const {
        container: containerStyles = {},
        image: imageStyles = {},
        caption: captionStyles = {},
        altText: altTextStyles = {}, // Destructure altTextStyles
        button: buttonStyles = {},
        indicators: indicatorsStyles = {},
        control: controlStyles = {},
    } = styles;

    return (
        <Carousel
            className="slider-container modern-slider"
            prevIcon={<span style={controlStyles}>‹</span>}
            nextIcon={<span style={controlStyles}>›</span>}
            indicators={items.length > 1}
            style={containerStyles}
        >
            {items && items.length > 0
                ? items.map((item, index) => (
                      <Carousel.Item key={index}>
                          <img
                              className="d-block w-100"
                              src={item.src}
                              alt={item.alt || `Slide ${index + 1}`}
                              style={{ objectFit: 'cover', height: '500px', ...imageStyles }}
                          />
                          <Carousel.Caption>
                              <div>
                                {item.alt && <h1 style={altTextStyles}><b>{item.alt}</b></h1>}
                                {item.caption && <p style={captionStyles}>{item.caption}</p>}
                                
                                  {item.buttonText && (
                                      <Button
                                          variant="primary"
                                          href={item.buttonLink || '#'}
                                          style={buttonStyles}
                                      >
                                          {item.buttonText}
                                      </Button>
                                  )}
                              </div>
                          </Carousel.Caption>
                      </Carousel.Item>
                  ))
                : <div>No items to display</div>}
        </Carousel>
    );
}

export default ModernCarousel;
