import React from 'react';
import SingleColumnContactForm from './templates/contactForm/SingleColumnContactForm';
import TwoColumnContactForm from './templates/contactForm/TwoColumnContactForm';

function ContactFormComponent({ formFields, template = 'SingleColumn', styles = {}, onSubmit }) {
    switch (template) {
        case 'TwoColumn':
            return React.createElement(TwoColumnContactForm, { formFields, styles, onSubmit });
        case 'SingleColumn':
        default:
            return React.createElement(SingleColumnContactForm, { formFields, styles, onSubmit });
    }
}

export default ContactFormComponent;
