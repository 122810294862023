import React, { useState } from 'react';
import { Form, Button, Image } from 'react-bootstrap';
import MediaLibrary from '../MediaLibrary';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

const CarouselForm = ({ formValues, setFormValues, handleAddJsonArrayItem, handleRemoveJsonArrayItem }) => {
    const [showLibrary, setShowLibrary] = useState({}); // Track which item is opening the MediaLibrary

    const renderJsonArrayFields = (fieldName, itemSchema) => (
        <>
            {formValues[fieldName]?.map((item, index) => (
                <div key={index} className="carousel-wrap">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="media-img-wrap">
                                <Form.Group>
                                    {item.src ? (
                                        <Image 
                                            src={item.src} 
                                            alt={item.alt} 
                                            thumbnail 
                                            style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                                        />
                                    ) : (
                                        <div className="carousel-no-img">
                                            No image selected
                                        </div>
                                    )}
                                    <MediaLibrary
                                        onSelectImage={(url) => {
                                            const updatedItems = [...formValues[fieldName]];
                                            updatedItems[index].src = url; // Set the selected image URL to 'src' of the item
                                            setFormValues({ ...formValues, [fieldName]: updatedItems });
                                            setShowLibrary({ [index]: false }); // Close the media library for the item
                                        }}
                                    />
                                </Form.Group>
                            </div>
                        </div>
                        <div className="col-md-9">
                            {Object.keys(itemSchema).map((key) => key !== 'src' && (
                                <div key={key} className="mb-3">
                                    <label className="form-label">
                                        {key.charAt(0).toUpperCase() + key.slice(1)}:
                                    </label>
                                    <Form.Control
                                        type="text"
                                        placeholder={`Enter ${key.charAt(0).toUpperCase() + key.slice(1)}`}
                                        value={item[key]}
                                        onChange={(e) => {
                                            const updatedItems = [...formValues[fieldName]];
                                            updatedItems[index][key] = e.target.value;
                                            setFormValues({ ...formValues, [fieldName]: updatedItems });
                                        }}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                    <Button variant="danger" className="mt-2" onClick={() => handleRemoveJsonArrayItem(fieldName, index)}>
                        Remove Slide
                    </Button>
                </div>
            ))}
            <Button variant="primary" className="add-more-media" onClick={() => handleAddJsonArrayItem(fieldName, itemSchema)}>
                <FontAwesomeIcon icon={faPlus} /> Add More
            </Button>
        </>
    );

    return (
        <>
            {renderJsonArrayFields('items', { src: '', alt: 'Alt Text', caption: 'Caption', buttonText: 'Button Text' })}
            <Form.Group controlId="formCarouselFullscreen" className="mt-3">
                <Form.Check
                    type="checkbox"
                    label="Display as full-screen at the top"
                    name="isFullScreen"
                    checked={formValues.isFullScreen || false}
                    onChange={(e) =>
                        setFormValues({
                            ...formValues,
                            isFullScreen: e.target.checked
                        })
                    }
                />
            </Form.Group>
        </>
    );
};

export default CarouselForm;
