import React from 'react';

const LightboxGalleryComponent = ({ images }) => {
    const cleanedImages = images.map((image) => ({
        src: image.src.replace(/\\\//g, '/'), // Clean up escaped slashes
        caption: image.caption || 'No caption available', // Fallback caption
    }));

    return (
        <div className="lightbox-gallery">
            {cleanedImages.map((image, idx) => (
                <a
                    key={idx}
                    href={image.src}
                    data-lightbox="gallery"
                    data-title={image.caption}
                >
                    <img
                        src={image.src}
                        alt={image.caption}
                        className="img-thumbnail"
                        style={{
                            width: '100px',
                            height: '100px',
                            objectFit: 'cover',
                            margin: '5px',
                        }}
                    />
                </a>
            ))}
        </div>
    );
};

export default LightboxGalleryComponent;
