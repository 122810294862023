import React from 'react';
import ClassicTemplate from './templates/testimonial/ClassicTemplate';
import ModernTemplate from './templates/testimonial/ModernTemplate';
import ImageOnTopTemplate from './templates/testimonial/ImageOnTopTemplate';
import SplitScreenTemplate from './templates/testimonial/SplitScreenTemplate';
import TextOnlyTemplate from './templates/testimonial/TextOnlyTemplate';
import CenterAlignedTemplate from './templates/testimonial/CenterAlignedTemplate';

const TestimonialComponent = ({ template = "Classic", ...props }) => {
    switch (template) {
        case "Modern":
            return <ModernTemplate {...props} />;
        case "ImageOnTop":
            return <ImageOnTopTemplate {...props} />;
        case "SplitScreen":
            return <SplitScreenTemplate {...props} />;
        case "TextOnly":
            return <TextOnlyTemplate {...props} />;
        case "CenterAligned":
            return <CenterAlignedTemplate {...props} />;
        default:
            return <ClassicTemplate {...props} />;
    }
};

export default TestimonialComponent;
