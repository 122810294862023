import React from 'react';
import './BlockquoteComponent.css'; // Import CSS file

function BlockquoteComponent({ template = "default", quote, author, styles = {} }) {
    const containerClassName = `${template.toLowerCase()}`; // Class for parent div

    // Apply styles from the `styles` prop
    const blockquoteStyles = {
        fontFamily: styles.blockquote?.fontFamily,
        fontSize: styles.blockquote?.fontSize,
        color: styles.blockquote?.color,
        fontStyle: styles.blockquote?.fontStyle,
        textAlign: styles.blockquote?.textAlign,
        borderLeftWidth: styles.blockquote?.borderLeftWidth,
        borderLeftColor: styles.blockquote?.borderLeftColor,
        borderLeftStyle: styles.blockquote?.borderLeftStyle || 'solid',
        lineHeight: styles.blockquote?.lineHeight,
    };

    const footerStyles = {
        fontFamily: styles.footer?.fontFamily,
        fontSize: styles.footer?.fontSize,
        color: styles.footer?.color,
        fontStyle: styles.footer?.fontStyle,
        textAlign: styles.footer?.textAlign,
        lineHeight: styles.footer?.lineHeight,
    };

    switch (template) {
        case "Modern":
        return (
            <blockquote className={containerClassName} style={blockquoteStyles}>
                <p>{quote || "Default quote text goes here."}</p>
                <footer className="blockquote-footer" style={footerStyles}>
                    {author || "Unknown Author"}
                </footer>
            </blockquote>
        );

        case "Minimal":
        return (
            <blockquote className={containerClassName} style={blockquoteStyles}>
                <p>{quote || "Default quote text goes here."}</p>
                <footer className="blockquote-footer" style={footerStyles}>
                    {author || "Unknown Author"}
                </footer>
            </blockquote>
        );

        case "Roundedquote":
        return (

            <blockquote className={containerClassName} style={blockquoteStyles}>
                <div className="quote-content">
                    <p>{quote || "Default quote text goes here."}</p>
                    <footer className="blockquote-footer" style={footerStyles}>
                        {author || "Unknown Author"}
                    </footer>
                </div>
            </blockquote>
        );
        default: // Grid
            return (
                <blockquote className={containerClassName} style={blockquoteStyles}>
                    <p>{quote || "Default quote text goes here."}</p>
                    <footer className="blockquote-footer" style={footerStyles}>
                        {author || "Unknown Author"}
                    </footer>
                </blockquote>
            );
    }
}

export default BlockquoteComponent;
