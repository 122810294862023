import React from 'react';
import { Card } from 'react-bootstrap';

const FeatureCardsComponent = ({ headline, description, features, styles = {} }) => (
    <div className="feature-cards-section">
        {/* Headline and Description */}
        <div className="feature-header text-center mb-4">
            <h2 style={styles.headline || {}}>{headline}</h2>
            <p style={styles.description || {}}>{description}</p>
        </div>

        {/* Feature Cards */}
        <div className="product-inner-wrap">
            <div className="g-4 row">
                {features.map((feature, idx) => (
                    <div key={idx} className="col-md-4">
                        <Card
                            className="h-100"
                            style={styles.card || {}} // Card styling
                        >
                            <div className="image-wrap">
                                <Card.Img
                                    variant="top"
                                    src={feature.image}
                                    alt={feature.title}
                                    style={{
                                        height: styles.imageHeight || '200px',
                                        objectFit: styles.imageObjectFit || 'cover',
                                        ...styles.image, 
                                    }}
                                />
                            </div>
                            <Card.Body>
                                <Card.Title style={styles.title || {}}>
                                    {feature.title}
                                </Card.Title>
                                <Card.Text
                                    style={styles.text || {}}
                                    className="feature-description"
                                >
                                    {feature.description}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                ))}
            </div>
        </div>
    </div>
);

export default FeatureCardsComponent;
