import React from 'react';
import { Form, Button, Image } from 'react-bootstrap';
import MediaLibrary from '../MediaLibrary';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

const LightboxGalleryForm = ({ formValues, setFormValues, handleAddJsonArrayItem, handleRemoveJsonArrayItem }) => {
    const renderJsonArrayFields = (fieldName, itemSchema) => (
        <>
            {formValues[fieldName]?.map((item, index) => (
                <div key={index} className="carousel-wrap">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="media-img-wrap">
                                {item.src ? (
                                    <Image 
                                        src={item.src} 
                                        alt={`Image ${index + 1}`} 
                                        thumbnail 
                                        style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                                    />
                                ) : (
                                    <div
                                        style={{
                                            width: '100px',
                                            height: '100px',
                                            backgroundColor: '#ddd',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            margin: 'auto',
                                        }}
                                    >
                                        No Image
                                    </div>
                                )}

                                {/* MediaLibrary Integration */}
                                <MediaLibrary
                                    onSelectImage={(url) => {
                                        const updatedItems = [...formValues[fieldName]];
                                        updatedItems[index].src = url;
                                        setFormValues({ ...formValues, [fieldName]: updatedItems });
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-md-9">
                            {Object.keys(itemSchema).map((key) => key !== 'src' && (
                                <Form.Control
                                    key={key}
                                    type="text"
                                    placeholder={key.charAt(0).toUpperCase() + key.slice(1)}
                                    value={item[key] || ''}
                                    onChange={(e) => {
                                        const updatedItems = [...formValues[fieldName]];
                                        updatedItems[index][key] = e.target.value;
                                        setFormValues({ ...formValues, [fieldName]: updatedItems });
                                    }}
                                    className="mb-2"
                                />
                            ))}
                        </div>
                    </div>
                    <Button variant="danger" className="mt-2" onClick={() => handleRemoveJsonArrayItem(fieldName, index)}>
                        Remove Image
                    </Button>
                </div>
            ))}
            <Button variant="primary" className="add-more-media" onClick={() => handleAddJsonArrayItem(fieldName, itemSchema)}>
                <FontAwesomeIcon icon={faPlus} /> Add Image
            </Button>
        </>
    );

    return (
        <>
            {renderJsonArrayFields('images', { src: '', caption: '' })}
        </>
    );
};

export default LightboxGalleryForm;
