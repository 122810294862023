import React from 'react';
import { Carousel } from 'react-bootstrap';
import './CarouselTemplate.css'; // Add your styles here if needed

const CarouselTemplate = ({ images }) => {
    return (
        <Carousel className="slider-container classic-slider gallery-slider">
            {images && images.length > 0 ? (
                images.map((item, index) => (
                    <Carousel.Item key={index}>
                        <img
                            className="d-block w-100"
                            src={item.src}
                            alt={item.alt || `Slide ${index + 1}`}
                            style={{ objectFit: 'cover', height: '' }}
                        />
                        {item.caption && ( // Conditionally render Carousel.Caption
                            <Carousel.Caption>
                                <div>
                                    <h1>{item.caption}</h1>
                                    <p><b>{item.alt || `Slide ${index + 1}`}</b></p>
                                </div>
                            </Carousel.Caption>
                        )}
                    </Carousel.Item>
                ))
            ) : (
                <div>No images available</div>
            )}
        </Carousel>
    );
};

export default CarouselTemplate;
